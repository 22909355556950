import { makeAutoObservable, toJS } from 'mobx'


// Model the application state.
class LoginCheck {

  loginOn = false
  cip = false
  role = ''

  constructor() {
    makeAutoObservable(this)
  }

  setLogin(bool) {
    this.loginOn = bool
  }

  setRole(role){
    this.role = role
  }

  setCip(bool) {
    this.cip = bool
  }

  get getLogin() {
    return toJS(this.loginOn)
  }

  get getRole(){
    return toJS(this.role)
  }

  get getCip() {
    return toJS(this.cip)
  }
}

export const loginCheck = new LoginCheck()
