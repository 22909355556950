import React, {lazy, Suspense, useEffect} from 'react'
import { observer } from 'mobx-react'
import {themeSwitcher} from '../stores/ThemeSwitcher'
import { I18nextProvider } from 'react-i18next'
import i18n from '../translates/i18n'
import ScrollToTop from './utils/ScrollToTop/ScrollToTop'
import Loader from './utils/Loader/Loader'
import '../assets/main.scss'

import 'react-image-lightbox/style.css'
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom'
import Header from './Header/Header'
import Footer from './Footer/Footer'
// import {loginCheck} from '../stores/LoginCheck'
import {Result} from 'antd'
import {ThemeProvider} from 'styled-components'
import { toJS } from 'mobx'
import { useTranslation } from 'react-i18next'

import SinglePassangers from './Passengers/Single'
import { loginCheck } from '../stores/LoginCheck'

const lazyImport = (
  filename
) => (
  lazy(() => (
     import(`${filename}`)
  ))
)

const Schedule = lazyImport('./Schedule/Schedule')
const Cabinet = lazyImport('./Cabinet/Cabinet')
const Cip = lazyImport('./Cip/Cip')
const About = lazyImport('./About/About')
const News = lazyImport('./News/News')
const Tenders = lazyImport('./Tenders/Tenders')
const Privacy = lazyImport('./Privacy/Privacy')
const UserAgreement = lazyImport('./UserAgreement/UserAgreement')
const CertificatesNews = lazyImport('./CertificatesNews/CertificatesNews')
const Single = lazyImport('./News/Single')
const SingleT = lazyImport('./Tenders/Single')
const SingleC = lazyImport('./CertificatesNews/Single')
const Contacts = lazyImport('./Contacts/Contacts')
const Certificates = lazyImport('./Certificates/Certificates')
const Partners = lazyImport('./Partners/Partners')
const SingleP = lazyImport('./Partners/Single')
const Passengers = lazyImport('./Passengers/Passengers')
const Vacancy = lazyImport('./Vacancy/Vacancy')
const OnlineSchedule = lazyImport('./OnlineSchedule/OnlineSchedule')
const Main = lazyImport('./Main/Main')
const AdminLogin = lazyImport('./Admin/Login/Login')
const Admin = lazyImport('./Admin/MainPage/MainPage')
const Documents = lazyImport('./Documents/Documents')
const History = lazyImport('./About-history/History')

const App = observer(() => {
  const {t} = useTranslation()
  useEffect(() => {
    if(window.location.pathname.indexOf('admin-airport') === 1) {
      document.querySelector('#root').style.paddingBottom = 0
    }
  }, [location.pathname])

  return (
    <I18nextProvider i18n={i18n}>
      <ThemeProvider theme={toJS(themeSwitcher.theme)}>
        <Router>
          <ScrollToTop>
            <Header/>
            <Suspense fallback={<Loader/>}>
              <Switch>
                <Route exact path='/' component={Main}/>
                <Route path='/admin-airport' component={sessionStorage.getItem('admin_token') ? Admin : AdminLogin}/>
                <Route path='/schedule' component={Schedule}/>
                <Route path='/onlain-tablo' component={OnlineSchedule}/>
                <Route path='/cip' render={()=>{
                  if(localStorage.getItem('login') === 'on' || sessionStorage.getItem('login') === 'on') {
                    return <Cip/>
                  }
                  loginCheck.setCip(true)
                  return <Redirect to='/?login'/>
                }}/>
                <Route path='/about/history' component={History}/>
                <Route path='/about' component={About}/>

                <Route path='/documents' component={Documents}/>
                <Route path='/news/:id' component={Single}/>
                <Route path='/news' component={News}/>
                <Route path='/tenders/:id' component={SingleT}/>
                <Route path='/tenders' component={Tenders}/>
                <Route path='/certificates-news/:id' component={SingleC}/>
                <Route path='/certificates-news' component={CertificatesNews}/>
                <Route path='/contacts' component={Contacts}/>
                <Route path='/certificates' component={Certificates}/>
                <Route path='/certificates/:id' component={Certificates}/>
                <Route path='/partners/:id' component={SingleP}/>
                <Route path='/partners' component={Partners}/>
                <Route path='/vacancy' component={Vacancy}/>
                <Route path='/passengers/:id' component={SinglePassangers}/>
                <Route path='/passengers' component={Passengers}/>

                <Route path='/privacy' component={Privacy}/>
                <Route path='/user-agreement' component={UserAgreement}/>
                <Route path='/cabinet' render={()=>{
                  if(localStorage.getItem('login') === 'on' || sessionStorage.getItem('login') === 'on') {
                    return <Cabinet/>
                  }
                  return <Redirect to='/?login'/>
                }}/>
                <Route render={()=>(
                  <Result
                    style={{paddingTop: 110, paddingBottom: 10}}
                    status="404"
                    title="404"
                    subTitle={t('does not exist')}
                  />
                )}/>

              </Switch>
            </Suspense>
            <Footer/>
          </ScrollToTop>
        </Router>
      </ThemeProvider>
    </I18nextProvider>
  )
})

export default App
