import axios from 'axios'
import Cookies from 'universal-cookie'
import { URLSearchParams } from 'core-js/modules/web.url-search-params'

const cookies = new Cookies(null, { path: '/' })

export const url = process.env.MAIN_URL
export const add_url = process.env.ADD_URL
let retry = 0


//export const add_url = process.env.ADD_URL
export const followerUrl = process.env.MAIN_FOLLOWERURL
export const base_username = 'aeroport_gp_70e'
export const base_pass = 'I394E4P2_RGT'


// /v1/gp-follower/transactions/revert/{id}
//


export const instance = axios.create({
  baseURL: add_url
})

export const clientInstance = axios.create({
  baseURL: add_url
})

export const instanceFollower = axios.create({
  baseURL: followerUrl
})

export const adminInstance = axios.create({
  baseURL: add_url
})


clientInstance.interceptors.request.use(
  (config) => {
    if (!config.headers['Content-Type']) {
      config.headers['Content-Type'] = 'application/json'
    }
    const token = cookies.get('token') || sessionStorage.getItem('token')

    if (token) {
      config.headers.Authorization = `Bearer ${token}`
    }
    return config
  }, (error) => {
    Promise.reject(error)
  }
)

clientInstance.interceptors.response.use(
  (response) => {
    return response
  },
  function(error) {
    const originalRequest = error.config
    let status = (error.response && error.response.status) || 0


    if (status === 401 && !originalRequest._retry) {

      if (retry > 0) {
        originalRequest._retry = true
        retry = 0


        cookies.remove('id')
        cookies.remove('refresh_token')
        cookies.remove('token')
        cookies.remove('username')
        cookies.remove('login')
        cookies.remove('role')
        sessionStorage.removeItem('id')
        sessionStorage.removeItem('refresh_token')
        sessionStorage.removeItem('token')
        sessionStorage.removeItem('username')
        sessionStorage.removeItem('login')
        sessionStorage.removeItem('role')
        window.location = '/?login'
      } else {
        retry++
      }

      if (sessionStorage.getItem('refresh_token')) {
        return LoginHome.refreshLogin(sessionStorage.getItem('refresh_token'), sessionStorage.getItem('username'))
          .then(({ data }) => {
            sessionStorage.setItem('id', data.id)
            sessionStorage.setItem('refresh_token', data.data.refreshtoken)
            sessionStorage.setItem('token', data.data.token)
            sessionStorage.setItem('username', data.data.username)
            sessionStorage.setItem('login', 'on')
            return clientInstance(originalRequest)
          })
          .catch(e => {
            if (e.response.status !== 401) {
              sessionStorage.removeItem('id')
              sessionStorage.removeItem('refresh_token')
              sessionStorage.removeItem('token')
              sessionStorage.removeItem('username')
              sessionStorage.removeItem('login')
              window.location = '/?login'
            }
          })
      } else {
        return LoginHome.refreshLogin(cookies.get('refresh_token'), cookies.get('username'))
          .then(({ data }) => {
            cookies.set('id', data.id)
            cookies.set('refresh_token', data.data.refreshtoken)
            cookies.set('token', data.data.token)
            cookies.set('username', data.data.username)
            cookies.set('login', 'on')
            return clientInstance(originalRequest)
          })
          .catch(e => {
            if (e.response.status !== 401) {
              console.log('Ошибка: ', e.response)
              cookies.remove('id')
              cookies.remove('refresh_token')
              cookies.remove('token')
              cookies.remove('username')
              cookies.remove('login')
              window.location = '/?login'
            }
          })
      }
    } else {
      throw error
    }
  }
)

adminInstance.interceptors.request.use(
  (config) => {
    if (!config.headers['Content-Type']) {
      config.headers['Content-Type'] = 'application/json'


    }
    const token = sessionStorage.getItem('admin_token')
    if (token) {
      config.headers.Authorization = `Bearer ${token}`
    }
    return config
  },
  (error) => {
    Promise.reject(error)
  }
)

adminInstance.interceptors.response.use(
  (response) => {
    return response
  },
  function(error) {

    const originalRequest = error.config
    let status = (error.response && error.response.status) || 0


    if (status === 401 && !originalRequest._retry) {

      if (retry > 0) {
        originalRequest._retry = true
        retry = 0
        sessionStorage.removeItem('admin_token')
        sessionStorage.removeItem('admin_tokenf')
        window.location = '/admin-airport/'
      } else {
        retry++
      }

      const refreshToken = sessionStorage.getItem('admin_tokenf')
      const username = sessionStorage.getItem('username')

      alert('Ваша сессия истекла, повторите попытку авторизации')

      return LoginAdmin.singIn({
        refresh_token: refreshToken,
        username: username
      })
        .then(({ data }) => {

          if (data.data) {
            retry = 0
            sessionStorage.setItem('admin_token', data.data.token)
            sessionStorage.setItem('admin_tokenf', data.data.refresh_token)
          } else {
            alert(data.message?.text)
            sessionStorage.removeItem('admin_token')
            sessionStorage.removeItem('admin_tokenf')
            window.location = '/admin-airport/'
          }

          return adminInstance(originalRequest)
        })
        .catch(() => {

          alert('Ваша сессия истекла, повторите попытку авторизации')
          retry = 0
          sessionStorage.removeItem('admin_token')
          sessionStorage.removeItem('admin_tokenf')
          window.location = '/admin-airport/'
        })
    } else {

      if (error.response.data.message) {
        alert(error.response.data.message.text)
      } else {
        alert(`Статус Ошибки ${error.response.status}`)
      }
      throw error
    }
  }
)


/*-------ADMIN API ---------*/

export const LoginAdmin = {
  singIn(data) {

    return adminInstance
      .post('/api/v1/tokens', data)
      .then((response) => response)

  }
}

export const UsersApi = {
  getAll(params = {}) {
    return adminInstance
      .get('/api/v1/admin/user', { params })
      .then((response) => response)
  },

  getAdmins(params = {}) {
    return adminInstance
      .get('/api/v1/admin/moderator', { params })
      .then((response) => response)

  },

  addAdmin(data) {
    return adminInstance
      .post('/api/v1/admin/add/user', data)
      .then((response) => response)

  },

  deleteAdmin(username) {
    return adminInstance
      .delete('/api/v1/admin/user', { params: { username } })
      .then((response) => response)
  },

  addUser(data) {
    return adminInstance
      .post('/user', data)
      .then((response) => response)
  },

  deleteUser(username) {
    return adminInstance
      .delete(`/api/v1/admin/user?username=${username}`)
      .then((response) => response)
  },

  changeStatus(status, username) {
    return adminInstance
      .patch('/api/v1/admin/state', null, {
        params:
          { status, username }
      })
      .then((response) => response)
  },

  changePassword(data) {
    return adminInstance
      .patch('/password', data)
      .then((response) => response)
  },

  resetPassword(userName) {
    return adminInstance
      .put(`api/v1/users/${userName}`)
      .then((response) => response)
  },
  setNewPassword(data) {
    return adminInstance
      .patch('api/v1/users', data)
      .then((response) => response)
  }
}

export const AdminCIP = {
  getAll() {
    return adminInstance
      .get('/api/v1/admin/halls')
      .then((response) => response)
  },

  getOne(id) {
    return adminInstance
      .get(`/api/v1/admin/halls/${id}`)
      .then((response) => response)
  },

  addNew(params) {

    return adminInstance
      .put('/api/v1/admin/halls', params)
      .then((response) => response)
  },

  deleteHall(id) {
    return adminInstance
      .delete('/api/v1/admin/halls?id=' + id)
      .then((response) => response)
  },

  editHall(data) {
    return adminInstance
      .put('/api/v1/admin/halls', data)
      .then((response) => response)
  }
}

export const AdminRole = {
  getAll() {
    return adminInstance
      .get('/api/v1/admin/role')
      .then((response) => response)
  },

  createRole(data) {
    return adminInstance
      .post('/api/v1/admin/role', data)
      .then((response) => response)
  },

  deleteRole(data) {
    return adminInstance
      .delete('/role', data)
      .then((response) => response)
  }
}

export const AdminTransactions = {

  exportExcel(data) {
    return adminInstance
      .post('/api/v1/admin/transactions/export/excel', data)
      .then((response) => response)
  },
  getAll(params = null) {

    return adminInstance
      .get('/api/v1/admin/transactions', { params })
      .then((response) => response)
  },

  filters(dto, params) {

    return adminInstance
      .post('/api/v1/admin/transaction/by-filter', dto, { params })
      .then((response) => response)
  }
}

export const AdminStatistic = {
  getStatistic(fromDate, toDate) {
    return adminInstance
      .get(`api/v1/admin/transactions/statistics?fromDate=${fromDate}&toDate=${toDate}`)
      .then((response) => response)
  },

  getStatisticTop(fromDate, toDate) {
    return adminInstance
      .get(`api/v1/admin/transactions/statistics/top-bought?fromDate=${fromDate}&toDate=${toDate}`)
      .then((response) => response)
  },
  getStatisticTopFly(fromDate, toDate) {
    return adminInstance
      .get(`api/v1/admin/transaction/top?fromDate=${fromDate}&toDate=${toDate}`)
      .then((response) => response)
  },
  getStatsticPassenger(year) {
    return adminInstance
      .get(`api/v1/admin/transactions/statistics/history?year=${year}`)
      .then((response) => response)
  }
}

export const Certificate = {
  getAll(page = 1, size = 20, language = 'ru', search = '') {
    return adminInstance
      .get(`/api/v1/internal/certificate?page=${page}&size=${size}&key=${search}`, {
        headers: {
          'Accept-Language': language
        }
      })
      .then((response) => response)
  },
  getCertificate(id) {
    return adminInstance
      .get(`/api/v1/internal/certificate/${id}`)
      .then((responce) => responce)
  },
  createCertificate(data) {
    return adminInstance
      .post('/api/v1/internal/certificate', data)
      .then((responce) => responce)
  },
  editCertificate(id, data) {
    return adminInstance
      .put(`/api/v1/internal/certificate/${id}`, data)
      .then((responce) => responce)
  },
  deleteCertificate(id) {
    return adminInstance
      .delete(`/api/v1/internal/certificate/${id}`)
      .then((responce) => responce)
  },
  photo(id, data) {
    return adminInstance

      .put(`/api/v1/internal/certificate/${id}`, data)
      .then((responce) => responce)
  }
}

export const Document = {
  getAll(page = 1, size = 20, language = 'ru', search = '') {
    return adminInstance
      .get(`/api/v1/internal/document?page=${page}&size=${size}&key=${search}`, {
        headers: {
          'Accept-Language': `${language}`
        }
      })
      .then((response) => response)
  },
  getDocument(id) {
    return adminInstance
      .get(`/api/v1/internal/document/${id}?`)
      .then((responce) => responce)
  },
  createDocument(data) {
    return adminInstance
      .post('/api/v1/internal/document', data)
      .then((responce) => responce)
  },
  editDocument(id, data) {
    return adminInstance
      .put(`/api/v1/internal/document/${id}`, data)
      .then((responce) => responce)
  },
  deleteDocument(id) {
    return adminInstance
      .delete(`/api/v1/internal/document/${id}`)
      .then((responce) => responce)
  },
  photo(idOfPage, data, idOfDoc) {
    return adminInstance
      .put(`/api/v1/internal/document/${idOfPage}/file/${idOfDoc}`, data)
      .then((responce) => responce)
  }
}

export const Info = {
  getAll(page = 1, size = 20, language = 'ru', search = '') {
    return adminInstance
      .get(`/api/v1/internal/doc/info?page=${page}&size=${size}&key=${search}`, {
        headers: {
          'Accept-Language': `${language}`
        }
      })
      .then((response) => response)
  },
  getInfo(id) {
    return adminInstance
      .get(`/api/v1/internal/doc/info/${id}?`)
      .then((responce) => responce)
  },
  createInfo(data) {
    return adminInstance
      .post('/api/v1/internal/doc/info', data)
      .then((responce) => responce)
  },
  editInfo(id, data) {
    return adminInstance
      .put(`/api/v1/internal/doc/info/${id}`, data)
      .then((responce) => responce)
  },
  deleteInfo(id) {
    return adminInstance
      .delete(`/api/v1/internal/doc/info/${id}`)
      .then((responce) => responce)
  },
  photo(id, data, idOfDoc) {
    return adminInstance
      .put(`/api/v1/internal/doc/info/${idOfDoc}/file`, data)
      .then((responce) => responce)
  }
}

export const Vacancy = {
  getAll(page = 1, size = 20) {
    return adminInstance
      .get(`/api/v1/internal/vacancy?page=${page}&size=${size}`)
      .then((response) => response)
  },
  getVacancy(id) {
    return adminInstance
      .get(`/api/v1/internal/vacancy/${id}`)
      .then((responce) => responce)
  },
  createVacancy(data) {
    return adminInstance
      .post('/api/v1/internal/vacancy', data)
      .then((responce) => responce)
  },
  editVacancy(id, data) {
    return adminInstance
      .put(`/api/v1/internal/vacancy/${id}`, data)
      .then((responce) => responce)
  },
  deleteVacancy(id) {
    return adminInstance
      .delete(`/api/v1/internal/vacancy/${id}`)
      .then((responce) => responce)
  }
}

export const ArticlesData = {
  getAllNews(id = null, page = 1, perPage = 20) {
    return adminInstance
      .get(`/api/v1/internal/article${id ? `?id=${id}&page=${page}` : `?page=${page}`}&sort_dir=desc&sort_field=insDate&size=${perPage}`)
      .then((response) => response)
  },
  getAllTenders(id = null, page = 1, perPage = 20) {
    return adminInstance
      .get(`/api/v1/internal/tenders${id ? `?id=${id}&page=${page}` : `?page=${page}`}&sort_dir=desc&sort_field=insDate&size=${perPage}`)//${id?`?id=${id}&page=${page}`:`?page=${page}`}
      .then((response) => response)
  },
  getAllPartners(id = null, page = 1, perPage = 20) {
    return adminInstance
      .get(`/api/v1/internal/partners${id ? `?id=${id}&page=${page}` : `?page=${page}`}&sort_dir=desc&sort_field=insDate&size=${perPage}`)
      .then((response) => response)
  },
  getAllPassengers(id = null, page = 1, perPage = 20) {
    return adminInstance
      .get(`/api/v1/internal/passengers${id ? `?id=${id}&page=${page}` : `?page=${page}`}&sort_dir=desc&sort_field=insDate&size=${perPage}`)
      .then((response) => response)
  },
  getNews(id = null) {
    return adminInstance
      .get(`/api/v1/internal/article${id ? `?id=${id}` : ''}`)
      .then((response) => response)
  },
  getPassenger(id) {
    return adminInstance
      .get(`/api/v1/internal/passengers/${id}`)
      .then((response) => response)
  },
  getPartner(id) {
    return adminInstance
      .get(`/api/v1/internal/partners/${id}`)
      .then((response) => response)
  },
  createNews(data) {
    return adminInstance
      .post('/api/v1/internal/article', data)
      .then((response) => response)
  },
  createTenders(data) {
    return adminInstance
      .post('/api/v1/internal/tender', data)
      .then((response) => response)
  },
  createPassenger(data) {
    return adminInstance
      .post('/api/v1/internal/passengers', data)
      .then((response) => response)
  },
  createPartner(data) {
    return adminInstance
      .post('/api/v1/internal/partners', data)
      .then((response) => response)
  },
  editNews(id, article) {
    return adminInstance
      .put(`/api/v1/internal/article/${id}`, article)
      .then((response) => response)
  },
  editTender(id, article) {
    return adminInstance
      .put(`/api/v1/internal/tender/${id}`, article)
      .then((response) => response)
  },
  getTender(id) {
    return adminInstance
      .get(`/api/v1/internal/tender/${id ? `?id=${id}` : ''}`)
      .then((response) => response)
  },
  editPassenger(id, article) {

    return adminInstance
      .put(`/api/v1/internal/passengers/${id}`, article)
      .then((response) => response)
  },
  editPartner(id, article) {
    return adminInstance
      .put(`/api/v1/internal/partners/${id}`, article)
      .then((response) => response)
  },

  editPictureArticle(id, file) {
    return adminInstance
      .put(`/api/v1/internal/article/file/${id}`, file)
      .then((response) => response)
  },

  editPicturePassangers(id, file, uuid) {
    return adminInstance
      .put(`/api/v1/internal/passengers/${id}photo/${uuid}`, file)
      .then((response) => response)
  },
  editPicturePartners(id, file, uuid) {
    return adminInstance
      .put(`/api/v1/internal/partners/${id}/photo/${uuid}`, file)
      .then((response) => response)
  },
  editPictureTender(id, file) {
    return adminInstance
      .put(`/api/v1/internal/tender/photo/${id}`, file)
      .then((response) => response)
  },
  deleteNews(id) {
    return adminInstance
      .delete(`/api/v1/internal/article/?id=${id}`)
      .then((response) => response)
  },
  deleteTender(id) {
    return adminInstance
      .delete(`/api/v1/internal/tender/?id=${id}`)
      .then((response) => response)
  },
  deletePartner(id) {
    return adminInstance
      .delete(`/api/v1/internal/partners/${id}`)
      .then((response) => response)
  },
  deletePassenger(id) {
    return adminInstance
      .delete(`/api/v1/internal/passengers/${id}`)
      .then((response) => response)
  },
  addPhotoPassenger(id, photo) {
    return adminInstance
      .put(`/api/v1/passengers/photos/${id}`, photo)
      .then((response) => response)
  },
  getFiles(name) {
    return `${add_url}/api/v1/internal/file?name=${name}`
  }
}

export const LogsApi = {
  getAll(params = null) {
    return adminInstance
      .get('/api/v1/internal/log', { params })
      .then((response) => response)
  }
}


export const VacancyClient = {
  getAll() {
    return instance
      .get('/api/v1/internal/vacancy')
      .then((response) => response)
  },
  getVacancy(id) {
    return instance
      .get(`/api/v1/internal/vacancy/${id}`)
      .then((responce) => responce)
  }
}


export const DocumentsClient = {
  getAll() {
    return instance
      .get('/api/v1/internal/document')
      .then((response) => response)
  },
  getInfo() {
    return instance
      .get('/api/v1/internal/doc/info')
      .then((response) => response)
  }
}


export const LoginHome = {
  reg(params) {
    return instance
      .post('/api/v1/users', params, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      })
      .then((response) => response)
  },
  validate(otp, username) {
    return instance
      .post('/api/v1/validate', { otp, username })
      .then((response) => response)
  },
  setUserPass(password, username) {
    return instance
      .patch('/api/v1/users', { password, username })
      .then((response) => response)
  },
  singIn(password, username) {
    return instance
      .post('/api/v1/tokens', { password, username })
      .then((response) => response)
  },
  refreshLogin(refresh_token, username) {
    return clientInstance
      .post('/api/v1/tokens', { refresh_token, username })
      .then((response) => response)
      .catch(() => {
        sessionStorage.removeItem('id')
        sessionStorage.removeItem('refresh_token')
        sessionStorage.removeItem('token')
        sessionStorage.removeItem('username')
        sessionStorage.removeItem('login')
        window.location = '/?login'
      })

  },
  resetPass(username) {
    return instance
      .put(`api/v1/users/${username}`)
      .then((response) => response)
  }
}

export const FlightInfo = {
  getFlights(params) {
    //instance
    return instance
      .get('api/v1/flight/flights', { params }, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      })
      .then((response) => response.data)
  },
  connectionFlights(dstCityIATA, srcCityIATA) {
    const params = new URLSearchParams()
    params.append('dstCityIATA', dstCityIATA)
    params.append('srcCityIATA', srcCityIATA)
    //instance
    return clientInstance
      .get('api/v1/flight/connection-flights', { params }, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      })
      .then((response) => response.data)
  },
  getFlightBanner(name)  {
    const params = new URLSearchParams()
    params.append('name', name)
    return instance.get('/banners/byname', {
      params: params
    })
  }
}



export const City = {
  connectionCities(cityName) {
    const params = new URLSearchParams()
    //instance
    params.append('cityName', cityName)

    return clientInstance
      .get('api/v1/flight/connection-cities', { params }, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      })
      .then((response) => response.data)
  }
}

export const CIP = {
  createCIP(params) {
    return clientInstance
      .post('api/v1/cip/cip-orders', { ...params, platform: 'WEB' })
      .then((response) => response.data)
  },
  getCIPHalls() {
    // return clientInstance
    //   .get('api/v1/cip/cip-halls', {headers: {'test': 'test'}})
    //   .then((response) => response.data)

    return clientInstance
      .get('api/v1/cip/cip-halls')
      .then((response) => response.data)
  },
  patchCIP(params) {
    return clientInstance
      .patch('api/v1/cip/cip-orders', params)
      .then((response) => response.data)
  },
  payCIP(cardId, orderId) {
    return clientInstance
      .patch('api/v1/cip/orders', { cardId, orderId })
      .then((response) => response.data)
  },
  payCIPNoToken(cardId, orderId) {
    return clientInstance
      .patch('api/v1/cip/orders', { cardId, orderId })
      .then((response) => response.data)
  },
  getHallByName(params) {
    return clientInstance
      .get('api/v1/cip/cip-flights', { params })
      .then((response) => response.data)
  },
  getOrders() {
    return clientInstance
      .get('api/v1/cip/orders')
      .then((response) => response.data)
  },
  getCheck(orderId) {
    return clientInstance
      .get(`api/v1/cip/orders/${orderId}`)
      .then((response) => response.data)
  }
}

export const CardAPI = {
  getAll() {
    return clientInstance
      .get('api/v1/cip/user-cards')
      .then((response) => response.data)
  },
  addCard(cardToken) {
    return clientInstance
      .post('api/v1/cip/user-cards', { cardToken })
      .then((response) => response.data)
  },
  deleteCard(id) {
    return clientInstance
      .delete(`api/v1/cip/user-cards/${id}`)
      .then((response) => response.data)
  }
}

export const FollowerAPI = {
  createCard(number, expiry_date) {
    return clientInstance
      .post('api/v1/cip/cards', { cardNumber: number, expiryDate: expiry_date })
      .then((response) => response)
  },
  confirmCard(cardToken, code) {
    return clientInstance
      .post(`api/v1/cip/code/confirm/${cardToken}`, { code: code })
      .then((response) => response)
  },
  resendCode(id) {
    return instanceFollower
      .post(`/v1/gp-follower/cards/${id}/resend-code`, null, {
        auth: {
          username: base_username,
          password: base_pass
        }
      })
      .then((response) => response)
  },

  refundTransaction(id, body) {
    const { refundType, amount } = body

    return adminInstance
      .post('/api/v1/cip/orders/revert', {
        orderId: id,
        returnType: refundType,
        amount: +amount
      })
      .then((response) => response)
  }
}

export const LogoHall = {
  getLogo(value) {
    return clientInstance
      .get(`api/v1/cip/cip-logo?value=${value}`)
      .then((response) => response.data)
  }
}


export const ArticlesClient = {

  getAllNews(id = null, page = 1, perPage = 20) {
    return clientInstance
      .get(`/api/v1/internal/article${id ? `?id=${id}&page=${page}` : `?page=${page}`}&sort_dir=desc&sort_field=insDate&size=${perPage}`)
      .then((response) => response)
  },

  getAllTenders(id = null, page = 1, perPage = 20) {
    return clientInstance
      .get(`/api/v1/internal/tenders${id ? `?id=${id}` : `?page=${page}`}&sort_dir=desc&sort_field=insDate&size=${perPage}`)//${id?`?id=${id}&page=${page}`:`?page=${page}`}
      .then((response) => response)
  },

  getTender(id) {
    return clientInstance
      .get(`/api/v1/internal/tender?id=${id}`)//${id?`?id=${id}&page=${page}`:`?page=${page}`}
      .then((response) => response)
  },

  getPartners() {
    return clientInstance
      .get('/api/v1/internal/partners')
      .then((response) => response.data)
  },
  getPartner(id) {
    return clientInstance
      .get(`/api/v1/internal/partners/${id}`)
      .then((response) => response.data)
  },
  getCertificates() {
    return clientInstance
      .get('/api/v1/internal/certificate')
      .then((response) => response.data)
  },
  getPassengers() {
    return clientInstance
      .get('/api/v1/internal/passengers')
      .then((response) => response.data)
  },

  getPassengerById(id) {
    return clientInstance
      .get(`/api/v1/internal/passengers/${id}`)
      .then((response) => response.data)
  }

}

export const PrivateAPI = {
  resetPass(newPassword, oldPassword) {
    return clientInstance
      .post('https://air-api.gs.uz/api/v1/private/password', { newPassword, oldPassword })
      .then((response) => response.data)
  }
}

