import styled from 'styled-components'
import {Link} from 'react-router-dom'
import { Collapse } from 'antd'

export const CustomLink = styled(Link)`
&&& {
  color: rgba(17, 17, 17, 0.3);
  &:hover {
    color: rgba(17, 17, 17, 1);
  }
}`

export const CustomCollapse = styled(Collapse)`
&&& {
  background: transparent;
  border: 0;
  .titles {
    font-size: 1.45vw;
  }
}`
