/* eslint-disable react/prop-types */
import React from 'react'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { useLocation, withRouter } from 'react-router'
// import {Button, Select, Menu, Dropdown} from 'antd'
import {
  Container,
  Logo,
  CustomLink
} from './styles'
// import {
//   CaretDownOutlined
// } from '@ant-design/icons'

const copy = new Date().getFullYear()

const Header = observer(() => {
  const {t} = useTranslation()
  const location = useLocation()

  return location.pathname.indexOf('admin-airport') === 1 ? undefined:(
    <Container>
      <div className="left">
        <Logo/>
        <div className="roboto-1">Islom Karimov Nomidagi</div>
        <div className="roboto-1">Toshkent Xalqaro Aerporti © {copy}</div>
      </div>
      <div className="center">
        <div className="first">
          <CustomLink to="/about" className={`tahoma ${location.pathname === '/about' ? 'active' : ''}`}>
            {t('about us')}
          </CustomLink>
          <CustomLink to="/contacts" className={`tahoma ${location.pathname === '/contacts' ? 'active' : ''}`}>
            {t('contacts')}
          </CustomLink>
          <CustomLink to="/vacancy" className={`tahoma ${location.pathname === '/vacancy' ? 'active' : ''}`}>
            {t('vacancy')}
          </CustomLink>
          <CustomLink to="/user-agreement" className={`tahoma ${location.pathname === '/user-agreement' ? 'active' : ''}`}>
            {t('footer.user-agreement')}
          </CustomLink>
          <CustomLink to="/privacy" className={`tahoma ${location.pathname === '/privacy' ? 'active' : ''}`}>
            {t('footer.privacy')}
          </CustomLink>
        </div>
        <div className="second">
          <div className="tahoma">{t('helper')}</div>
          <a href="tel:+998781402801"className="roboto-2">+998 78 140 28 01</a>
          <a href="tel:+998781402802"className="roboto-2">+998 78 140 28 02</a>
          <a href="tel:+998781402803"className="roboto-2">+998 78 140 28 03</a>
          <a href="tel:+998781402804"className="roboto-2">+998 78 140 28 04</a>
        </div>
      </div>
      <div className="right">
        <div className="top">
          <a href="https://t.me/tashkent_airport" target="_blank">
            <div className="tg"/>
            <div className="tg_hover"/>
          </a>
          <a href="https://www.instagram.com/tashkent.airport/" target="_blank">
            <div className="fb"/>
            <div className="fb_hover"/>
          </a>
          <a href="https://www.facebook.com/tashkent.airport.uz/" target="_blank">
            <div className="ins"/>
            <div className="ins_hover"/>
          </a>
        </div>
        <div className="bottom">
          <a href="https://apps.apple.com/uz/app/airport-tashkent/id1581648106" target="_blank" className="apple"/>
          <a href="https://play.google.com/store/apps/details?id=uz.global.ia" target="_blank" className="google"/>
        </div>
      </div>
    </Container>
  )
})

export default withRouter(Header)
