import styled from 'styled-components'
import bitmap from '../../img/Bitmap.jpg'
import {Button, Tabs} from 'antd'
import away from '../../img/away.svg'
import arrived from '../../img/arrived.svg'
import plane from '../../img/plane.svg'
import image from '../../img/image.svg'

export const AwayIcon = styled.div`
  filter: grayscale(1);
  background: url(${away}) center/contain no-repeat;
  width: 20px;
  height: 20px;
  opacity: 0.5;
  position: absolute;
  z-index: 10;
  top: 52px;
  left: 42px;
  &.move {
    display: none;
  }
  @media (max-width: 768px) {
    top: 42px;
    left: 21px;
  }
`
export const ArrivedIcon = styled.div`
  filter: grayscale(1);
  background: url(${arrived}) center/contain no-repeat;
  width: 20px;
  height: 17px;
  opacity: 0.5;
  position: absolute;
  z-index: 10;
  top: 13px;
  left: 10px;
  &.move {
    display: none;
  }
`
export const Section = styled.div`
&&& {
  padding: 160px 10% 70px 10%;
  background: linear-gradient(172.72deg, rgba(17, 17, 17, 0) -42.59%, rgba(17, 17, 17, 0.7) 94.33%), url(${bitmap}) center/cover no-repeat;
  h2 {
    font-size: 44px;
    max-width: 480px;
    color: rgba(255, 255, 255, 1);
    line-height: 44px;
    margin: 0px 0px 40px 0px;
    font-family: 'Helvetica Neue', sans-serif;
  }
  @media (max-width: 1025px) {
    padding: 160px 10% 70px 4%;
  }
  @media (max-width: 768px) {
    background: linear-gradient(172.72deg, rgba(17, 17, 17, 0) -42.59%, rgba(17, 17, 17, 0.7) 94.33%), url(${bitmap}) 54% 100%/cover no-repeat;
    h2 {
      font-size: 5vw;
    }
  }
  @media (max-width: 564px) {
    h2 {
      font-size: 6vw;
      line-height: 32px;
      text-align: center;
      margin: 0px auto 40px;
    }
  }
  @media (max-width: 425px) {
    h2 {
      font-size: 6.6vw;
      line-height: 32px;
      text-align: center;
      margin: 0px auto 40px;
    }
  }
  @media (max-width: 325px) {
    h2 {
      font-size: 7vw;
      line-height: 25px;
      text-align: center;
      margin: 0px auto 40px;
    }
  }
}
`


export const PublicOferta = styled.div`
    text-align: center;
    
    & > p{
        color: #A3A3A4;
        margin-bottom: 10px;
    }

    .ant-checkbox-checked .ant-checkbox-inner{
        background-color: #1DC41F;
        border-color: #1DC41F;
    }

    .ant-checkbox-wrapper{
        & > span:last-child{
            color: #A3A3A4;
            
            a{
                font-weight: 600;
                color: #47A669;
            }
        }
    }
    
`

export const CustomButton = styled(Button)`
&&& {
  background: #1DC41F;
  border-color: #1DC41F;
  border-radius: 10px;
  font-size: ${props => props.theme.val ? '16px' : '18px'};
  color: rgba(255, 255, 255, 1);
  padding: 11px 26px;
  height: auto;
  &[disabled] {
    background: #9a9a9a;
    border-color: #9a9a9a;
    &:hover {
      background: #9a9a9a;
      border-color: #9a9a9a;
    }
  }
  &:hover {
    background: #179e19;
    border-color: #179e19;
  }
  &[disabled] {
    opacity: 0.4;
  }
  @media (max-width: 564px) {
    margin: auto;
    display: block;
    &.info-btn {
      width: 100%;
    }
  }
}
`
export const MoreButton = styled(Button)`
&&& {
  border-color: rgba(163, 163, 164, 1);
  border-radius: 10px;
  font-size: ${props => props.theme.val ? '16px' : '18px'};
  color: rgba(163, 163, 164, 1);
  padding: 11px 26px;
  height: auto;
  @media (max-width: 564px) {
    margin: auto;
    display: block;
    &.info-btn {
      width: 100%;
    }
  }
  &:hover {
    color: #000000;
    border-color: #000000;
  }
}
`
export const Container = styled.div`
&&& {
  margin: 40px 0 60px 0;
  padding: 0px 10%;
    & .news-bottom-banner-single{
       margin-bottom: 15px; 
    }
  @media (max-width: 1025px) {
    padding: 10px 4%;
    margin: 20px 0 30px 0;
  }
  @media (max-width: 768px) {
    padding: 0px 3%;
    margin: 20px 0 30px 0;
  }
  td {
    border: 1px solid rgba(0,0,0,.1);
  }
  &.single-content {
    h2 {
      font-size: 2.6vw;
      line-height: 3.1vw;
      margin-bottom: 4vw;
      @media (max-width: 768px) {
        font-size: 3.5vw;
          line-height: 1.7;
      }
    }
      & .date{
          @media (max-width: 500px) {
              font-size: .7rem;
          }
      }
    p {
      font-size: 1.05vw;
      @media (max-width: 768px) {
        font-size: 3.5vw;
      }
    }
    ul {
      @media (max-width: 768px) {
        font-size: 2.5vw;
      }
      @media (max-width: 578px) {
        font-size: 3.5vw;
      }
    }
  }
  &.news-top-banner {
    margin: 0;
    padding: 0;
    .news-bottom-banner-single {
      padding: 0px 10%;
    }
    &.single {
      position: relative;
      min-height: 29vw;
      .tahoma.date {
        color: #FFFFFF;
        font-size: 12px;
        position: absolute;
        bottom: 2vw;
        @media (max-width: 768px) {
          left: 3%;
        }
      }
    }
    .tahoma.title {
      color: #FFFFFF;
      font-size: 3.25vw;
      line-height: 4.7vw;
      @media (max-width: 768px) {
          font-size: 4vw;
        }
    }
    .news-top-inner {
      padding: 165px 6% 70px;
      background: rgba(17,17,17,0.4);
      width: 100%;
      height: 100%;
      position: relative;
      @media (max-width: 768px) {
        padding: 125px 6% 90px;
      }
    }
    .news-category {
      padding: 4px;
      background: #0060BB;
      color: #FFFFFF;
      text-transform: uppercase;
      display: inline-block;
      position: absolute;
      top: 0;
      font-size: 0.77vw;
    }
    .news-bottom-banner {
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: absolute;
      width: 87vw;
      bottom: 1.5vw;
      .tahoma {
        color: #FFFFFF;
        font-size: 0.8vw;
        @media (max-width: 768px) {
          font-size: 2.5vw;
        }
      }
      .roboto-1 {
        color: #FFFFFF;
        font-size: 1vw;
        @media (max-width: 768px) {
          font-size: 3vw;
        }
      }
    }
  }
  &.vacancy-bottom-inner {
    margin: 0;
    .title {
      font-size: 3.1vw;
      line-height: 3.7vw;
      color: #ffffff;
      font-family: Helvetica Neue,sans-serif;
      font-weight: 700;
      @media (max-width: 768px) {
        text-align: center;
      }
      @media (max-width: 578px) {
        font-size: 3.5vw;
        line-height: normal;
    }
    }
    .custom-col {
      align-items: center;
      justify-content: flex-end;
      display: flex;
    }
  }
  &.vacancy-bottom {
    padding: 35px 0px;
    background: linear-gradient(172.72deg, rgba(17, 17, 17, 0) -42.59%, rgba(17, 17, 17, 0.7) 94.33%);
  }
  &.vacancy {
    padding: 0px;
    margin-top: 0px;
    h2 {
      text-align: center;
      font-size: 2.6vw;
      margin-bottom: 40px;
      @media (max-width: 768px) {
        font-size: 4.6vw;
        margin-bottom: 20px;
      }
      @media (max-width: 524px) {
        font-size: 5.6vw;
        margin-bottom: 20px;
      }
    }
  }
  &.certificates {
    padding: 0px;
    margin-top: 0px;
    h2 {
      font-size: 2.6vw;
      margin-bottom: 40px;
      @media (max-width: 768px) {
        font-size: 5.6vw;
        line-height: normal;
        margin-bottom: 20px;
      }
      @media (max-width: 524px) {
        font-size: 6.6vw;
        line-height: normal;
        margin-bottom: 20px;
      }
    }
  }
  &.contact-bottom {
    padding: 0px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
  &.contact-top {
    padding: 0px;
    h2 {
      color: #111111;
      font-size: 2.5vw;
      margin: 0;
      margin-bottom: 30px;
      @media (max-width: 768px) {
        font-size: 5.5vw;
        margin-bottom: 15px;
      }
    }
    .c-text {
      color: rgba(17, 17, 17, 0.6);
      font-size: 1.05vw;
      margin: 0px 0px 24px 0px;
      @media (min-width: 768px) and (max-width: 1024px) {
        font-size: 1.5vw;
      }
      @media (max-width: 768px) {
        font-size: 2.5vw;
        margin-bottom: 15px;
      }
      @media (max-width: 568px) {
        font-size: 3.5vw;
        margin-bottom: 15px;
      }
    }
    .title-contact {
      color: rgb(17, 17, 17);
      font-size: 1.05vw;
      margin: 0;
      @media (min-width: 768px) and (max-width: 1024px) {
        font-size: 1.5vw;
      }
      @media (max-width: 768px) {
        font-size: 2.5vw;
      }
      @media (max-width: 568px) {
        font-size: 3.5vw;
      }
    }
    .reception {
      margin-bottom: 20px;
      .c-text {
        margin: 0;
      }
    }
    .chancery {
      .c-text {
        margin: 0;
      }
    }
    .map {
      border-radius: 15px;
      margin-top: 40px;
      filter: grayscale(1);
      transition: filter 300ms ease-in-out;
      box-shadow: 0px 4px 4px rgba(17,17,17, 0.25);
      @media (max-width: 768px) {
        filter: grayscale(0);
      }
      &:hover {
        filter: grayscale(0);
      }
    }
  }
  &.about-top {
     padding: 0px;
    img {
      width: 100%;
      border-radius: 20px;
      box-shadow: 0px 4px 4px rgba(17,17,17, 0.25);
    }
    .about-title {
      font-size: 2.5vw;
      line-height: 3.2vw;
      margin-bottom: 30px;
      @media (max-width: 768px) {
        font-size: 3.5vw;
        line-height: normal;
        margin-bottom: 15px;
      }
    }
    .about-desc {
      color: rgba(17, 17, 17, 0.6);
      font-size: 1.55vw;
      @media (max-width: 768px) {
        font-size: 3.5vw;
        line-height: normal;
        margin-bottom: 15px;
      }
    }
  }
  &.about-middle {
     padding: 0px;
    .img {
      width: 100%;
      height: 240px;
      border-radius: 20px;
      box-shadow: 0px 4px 4px rgba(17,17,17, 0.25);
    }
    p {
      color: #111111;
      font-size: 1.05vw;
      line-height: 40px;
      @media (max-width: 768px) {
        font-size: 3.5vw;
        line-height: normal;
      }
    }
  }
  &.about-bottom {
     padding: 0px;
    .list-item {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      .icon-block {
        background: #D9EEFF;
        height: 64px;
        width: 64px;
        margin-right: 24px;
        border-radius: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 0 0 64px;
        max-width: 64px;
        @media (max-width: 768px) {
          height: 50px;
          width: 50px;
          flex: 0 0 50px;
          max-width: 50px;
        }
        .icon {
          width: 28px;
          height: 28px;
        }
      }
      .list-text {
        color: #111111;
        font-size: 1.05vw;
        @media (max-width: 768px) {
          font-size: 3.3vw;
        }
      }
    }
  }
  &#acc {
    .acc-tab {
      max-width: 46%;
      margin-top: 50px;
      @media (max-width: 768px) {
        max-width: 100%;
      }
      .input-row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 25px;
        .label {
          flex: 0 0 25%;
          max-width: 25%;
          @media (max-width: 768px) {
            flex: 0 0 33%;
            max-width: 33%;
          }
        }
        .input-val {
          flex: 0 0 40%;
          max-width: 40%;
          @media (max-width: 768px) {
            flex: 0 0 30%;
            max-width: 30%;
          }
        }
      }
    }
  }
  .breadcrumbs {
    color: rgba(17, 17, 17, 0.3);
    padding: 40px 0px;
    @media (min-width: 768px) and (max-width: 1024px) {
      padding: 20px 0px;
    }
    @media (max-width: 768px) {
      padding: 20px 0px;
    }
    span {
      color: rgba(17, 17, 17, 1);
    }
  }
  &.cip {
    position: relative;
    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      @media (max-width: 768px) {
        flex-wrap: wrap-reverse;
      }
      h2.tahoma {
        font-size: 32px;
        color: #111111;
        @media (max-width: 768px) {
          font-size: 6.5vw;
          margin: 15px 0px 0px;
          width: 100%;
        }
      }
    }
  }
  &.plane-form {
    background-color: #E5EBFF;
    &.clear {
      background-color: transparent;
    }
    .container {
      padding: 40px 0;
      h2 {
        font-family: 'Tahoma', sans-serif;
        font-weight: 700;
        font-size: 36px;
        margin-bottom: 16px;
        @media (max-width: 768px) {
          font-size: 5vw;
          text-align: center;
        }
        @media (max-width: 564px) {
          font-size: 6vw;
          text-align: center;
        }
      }
      .form-block {
        position: relative;
        padding: 38px 32px 14px 32px;
        .custom-btn-form {
          max-width: 150px !important;
          flex: 0 0 150px !important;
          margin-left: 20px !important;
          @media (max-width: 768px) {
            margin: auto !important;
            width: 100%;
            max-width: 100% !important;
            flex: 0 0 100% !important;
          }
        }
        .custom-form-input {
          position: relative;
          flex: 0 0 calc(49% - 109px);
          max-width: calc(49% - 109px);
          @media (max-width: 768px) {
            flex: 0 0 calc(48% - 24px);
            max-width: calc(48% - 24px);
          }
        }
        @media (max-width: 768px) {
          padding: 30px 10px 14px 10px;
        }
        border-radius: 20px;
        background-color: #ffffff;
        filter: drop-shadow(0px 2px 3px #D5DBEF);
        .ant-input-affix-wrapper {
          padding: 12px 14px;
          background: #F7F7F7;
          border-radius: 10px;
          input {
            background: #F7F7F7;
            color: #111111;
          }
        }
        .ant-form {
          display: flex;
          align-items: center;
          justify-content: space-between;
          @media (max-width: 768px) {
            flex-wrap: wrap;
          }
          .ant-form-item {
            row-gap: 0px;
            max-width: calc(49% - 109px);
            flex: 0 0 calc(49% - 109px);
            @media (max-width: 768px) {
              max-width: calc(48% - 24px);
              flex: 0 0 calc(48% - 24px);
            }
          }
          .anticon-sync {
            max-width: 40px;
            flex: 0 0 40px;
            background: rgba(0, 96, 187, 1);
            width: 40px;
            height: 40px;
            color: #ffffff;
            border-radius: 100px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 18px;
            cursor: pointer;
            transition: transform 500ms ease;
            position: relative;
            z-index: 10;
            margin: 0px 8px 24px;
            &.active {
              transform: rotate(-180deg);
            }
          }
        }
      }
    }
  }
  &.services {
    h2 {
        font-family: 'Tahoma', sans-serif;
        font-weight: 700;
        font-size: 36px;
        margin-bottom: 16px;
        @media (max-width: 768px) {
          font-size: 5vw;
          text-align: center;
        }
        @media (max-width: 564px) {
          font-size: 6vw;
          text-align: center;
        }
    }
    h3 {
      font-family: 'Tahoma', sans-serif;
      font-weight: 700;
      font-size: 20px;
    }
  }
  &.count {
    &.about-count {
      padding: 0px;
    }
    .count-item {
      border: 1px solid rgba(0, 96, 187, 0.5);
      background: #FFFFFF;
      border-radius: 20px;
      padding: 35px;
      @media (max-width: 768px) {
        padding: 22px;
      }
      transition: background-color 300ms ease;
      &:hover {
        background-color: rgba(0, 96, 187, 0.03);
      }
      .roboto-1 {
        font-size: 20px;
        text-align: center;
        position: relative;
        top: -10px;
      }
      .ibm {
        font-size: 56px;
        text-align: center;
        @media (max-width: 768px) {
          font-size: 7vw;
        }
      }
      .icon-parent {
        width: 60px;
        height: 60px;
        background: rgba(0,96,187,1);
        border-radius: 100px;
        position: relative;
        margin: auto;
        margin-bottom: 16px;
        .icon {
          background: url(${plane}) center/contain no-repeat;
          width: 30px;
          height: 30px;
          position: absolute;
          top: calc(50% - 15px);
          left: calc(50% - 15px);
        }
      }
    }
  }
  &.partners {
    position: relative;
    padding-bottom: 50px;
    h2 {
      font-family: 'Tahoma', sans-serif;
      font-weight: 700;
      font-size: 36px;
      margin-bottom: 16px;
      @media (max-width: 768px) {
        font-size: 5vw;
        margin-bottom: 30px;
      }
      @media (max-width: 564px) {
        font-size: 6vw;
      }
    }
    .item {
      background: #000000;
      border: 1px solid #F2F2F2;
      border-radius: 20px;
      width: 250px;
      height: 200px;
      @media (max-width: 768px) {
        margin: auto;
      }
    }
    .ant-carousel .slick-dots li button {
      background: inherit;
      width: 100%;
      height: 100%;
      border-radius: 100px;
    }
    .ant-carousel .slick-dots li.slick-active button {
      background: inherit;
      width: 100%;
      height: 100%;
      border-radius: 100px;
    }
    .ant-carousel {
      padding-bottom: 50px;
      .slick-dots-bottom {
        bottom: 52px;
      }
    }
    .slick-dots {
      li {
        border-radius: 100px;
        width: 10px;
        height: 10px;
        background-color: #F2F2F2;
        &.slick-active {
          background-color: #0060BB;
        }
      }
    }
    .slide-nav {
      background: #FFFFFF;
      border: 1px solid #A3A3A4;
      box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.0758031);
      border-radius: 100px;
      font-size: 24px;
      padding: 12px;
      color: #A3A3A4;
      position: absolute;
      top: 0;
      @media (max-width: 768px) {
        font-size: 15px;
        padding: 10px;
      }
      &.next {
        right: calc(6% + 70px);
        @media (max-width: 768px) {
          right: calc(4% + 70px);
        }
        @media (max-width: 564px) {
          right: calc(1% + 70px);
        }
      }
      &.prev {
        right: 6%;
      }
    }
    .ant-carousel .slick-slider {
      position: static;
    }
  }
  .auto-custom {
    .ant-select-selector {
      height: 44px;
      background: #F7F7F7;
      border-radius: 10px;
      .ant-select-selection-placeholder {
        line-height: 44px;
        padding-left: 25px;
      }
      .ant-select-selection-search {
        height: 44px;
        .ant-select-selection-search-input {
          height: 44px;
          padding-left: 25px;
        }
      }
    }
  }
}
`
export const MyTabs = styled(Tabs)`
&&& {
  .my-cards-tab {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    max-height: 547px;
    overflow-y: auto;
  }
  .no-flights {
    padding: 20px 0px;
    text-align: center;
    font-size: 1.3vw;
    @media (max-width: 768px) {
      font-size: 3.3vw;
    }
  }
  .ant-tabs-nav-list {
    width: 99%;
    margin: auto;
    .ant-tabs-tab {
      width: 100%;
      justify-content: center;
      .ant-tabs-tab-btn {
        font-family: 'Tahoma', sans-serif;
        font-weight: 700;
        font-size: 36px;
        color: rgba(0,0,0,0.3);
        text-shadow: none;
        @media (max-width: 768px) {
          font-size: 5vw;
        }
      }
      &.ant-tabs-tab-active {
        .ant-tabs-tab-btn {
          color: #000000;
        }
      }
    }
    .ant-tabs-ink-bar {
      background: #0060BB;
      height: 4px;
      border-radius: 20px;
    }
  }
  &.cabinet {
    .ant-tabs-tab + .ant-tabs-tab {
      margin: 0 0 0 15px;
    }
    .ant-tabs-nav-list .ant-tabs-tab .ant-tabs-tab-btn {
      font-size: 32px;
      @media (max-width: 1025px) {
        font-size: 3.5vw;
      }
      @media (max-width: 767px) {
        font-size: 4vw;
      }
    }
  }
}
`
export const MyTabsInner = styled(Tabs)`
&&& {
  .more-block {
    text-align: center;
    box-shadow: 0px -45px 45px 35px #ffffff;
    z-index: 10;
    position: relative;
    padding: 10px 0px;
    background: #ffffff;
  }
  .ant-tabs-nav {
    width: 320px;
    margin: auto;
    margin-bottom: 20px;
    @media (max-width: 768px) {
      width: 100%;
    }
  }
  .ant-tabs-nav:before {
    content: none;
  }
  .ant-tabs-nav-list {
    width: 99%;
    height: 41px;
    margin: auto;
    border: 1px solid rgba(0,0,0,0.2);
    border-radius: 20px;
    .ant-tabs-tab {
      margin: 0px;
      width: 100%;
      justify-content: center;
      .icon-tab {
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .icon-tab:before {
        content: '';
        display: block;
        width: 20px;
        height: 18px;
        margin-right: 10px;
        filter: grayscale(1);
        opacity: 0.4;
      }
      .icon-tab.departure:before {
          background: url(${away}) center/contain no-repeat;
        }
      .icon-tab.arrive:before {
        background: url(${arrived}) center/contain no-repeat;
      }
      .ant-tabs-tab-btn {
        font-family: 'Roboto', sans-serif;
        font-weight: 400;
        font-size: ${props => props.theme.val ? '16px' : '18px'};
        color: rgba(0,0,0,0.3);
      }
      &.ant-tabs-tab-active {
        .ant-tabs-tab-btn {
          color: #000000;
        }
        .icon-tab:before {
          filter: grayscale(0);
          opacity: 1;
        }
      }
    }
    .ant-tabs-ink-bar {
      height: 100%;
      background: transparent;
      border: 1px solid #0060BB;
      border-radius: 20px;
    }
  }
  .list-container {
    border: 1px solid rgba(17, 17, 17, 0.1);
    box-sizing: border-box;
    border-radius: 20px;
    position: relative;
    z-index: 1;
    &.maxHeight {
      max-height: 400px;
    }
    .list-item {
      padding: 16px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid rgba(17, 17, 17, 0.1);
      @media (max-width: 768px) {
        flex-wrap: wrap;
      }
        
      &:last-child {
        border-bottom: 0;
      }
      .left {
        display: flex;
        align-items: center;
        flex: 0 0 30%;
        max-width: 30%;
        position: relative;
          
        @media (max-width: 768px) {
          flex: 0 0 100%;
          max-width: 100%;
          position: relative;
          justify-content: space-between;
        }
      @media (max-width: 526px) {
          justify-content: flex-start;
      }
        img {
          margin-right: 40px;
          width: 40px;
          @media (max-width: 768px) {
            width: 50px !important;
            height: 50px !important;
          }
        @media (max-width: 526px) {
            width: 24px !important;
            height: 24px !important;
            margin-right: 24px;
        }
          &.group {
            margin-right: 40px;
             
          }
          &.second {
            position: absolute;
            left: 27px;
            border: 2px solid #ffffff;
            border-radius: 100px;
            margin: 0;
            width: 44px;
            height: 44px;
              @media (max-width: 526px) {
                  left: 20px;

              }
          }
        }
        div {
          line-height: normal;
          font-size: ${props => props.theme.val ? '16px' : '18px'};
          color: #000000;
            @media (max-width: 526px) {
                font-size: 12px;
                font-weight: bold;

            }
          &.desc {
            font-size: ${props => props.theme.val ? '16px' : '18px'};
            color: #A3A3A4;
          }
        }
      }
      .center {
        display: flex;
        align-items: center;
        flex: 0 0 33%;
        max-width: 33%;
        justify-content: space-between;
        font-size: ${props => props.theme.val ? '16px' : '18px'};
        @media (max-width: 768px) {
          flex: 0 0 100%;
          max-width: 100%;
          margin-top: 20px;
        }
          @media (max-width: 526px) {
              margin-top: 5px;

          }
        .roboto-1 {
          max-width: 45%;
          flex: 0 0 45%;
        }
        .inner-block {
          max-width: 50%;
          flex: 0 0 50%;
          @media (max-width: 768px) {
            .roboto-2 {
              margin-left: auto;
            }
          }
        }
      }
      .right {
        display: flex;
        align-items: center;
        flex: 0 0 27%;
        max-width: 27%;
        justify-content: center;
        font-size: ${props => props.theme.val ? '16px' : '18px'};
        @media (max-width: 768px) {
          margin-top: 12px;
          flex: 0 0 100%;
          max-width: 100%;
        }
          @media (max-width: 526px) {
              justify-content: flex-end;

          }
      }
    }
  }
}
`
export const CenterItem = styled.div`
&&& {
  color: #ffffff;
  background: ${props => {
    switch (props.value) {
    case 'REGISTRATION': return 'linear-gradient(180deg, #605CF3 0%, #4D4AC8 100%)'
    case 'BOARDING': return 'linear-gradient(180deg, #08B395 0%, #0C977F 100%)'
    case 'ARRIVAL': return 'linear-gradient(180deg, #1ac05c 0%, #0c955c 100%)'
    case 'DELAYED': return 'linear-gradient(180deg, #FCA549 0%, #F39531 100%)'
    case 'BOARDING_OVER': return 'linear-gradient(180deg, #d69752 0%, #cb771f 100%)'
    case 'CANCELED': return 'linear-gradient(180deg, #FF5959 0%, #EF2C2C 100%),linear-gradient(180deg, #FF4B6E 0%, #EC234A 100%)'
    case 'BOARDING_CLOSE': return 'linear-gradient(180deg, #6FCBFF 0%, #2D9CDB 100%)'
    default: return 'linear-gradient(180deg, #6989FE 0%, #3C64F4 100%)'
    }}};
  padding: 1px 10px;
  border-radius: 50px;
  width: fit-content;
}
`
export const Card = styled.div`
  position:relative;
  background: url(${image}) center/40% no-repeat rgba(64, 77, 97, 0.08);
  border-radius: 20px;
  margin-bottom: 14px;
  overflow: hidden;
  height: 250px;
  transition: all 300ms ease;
  cursor: pointer;
  &:hover {
    box-shadow: 0px 6px 10px -3px #000000;
    transform: translateY(-2px);
  }
`
export const NewsCard = styled.div`
&&& {
  position: relative;
  overflow: hidden;
  background: #FFFFFF;
  border: 1px solid #F2F2F2;
  border-radius: 20px;
  transition: all 300ms ease;
  &:hover {
    box-shadow: 0px 6px 10px -3px #000000;
    transform: translateY(-2px);
    cursor: pointer;
  }
  .img {
    background: url(${image}) center/40% no-repeat rgba(64, 77, 97, 0.08);
    height: 340px;
    position: relative;
    .category {
      position: absolute;
      top: 0;
      left: 25px;
      background: rgba(0, 96, 187, 1);
      padding: 5px;
      color: #FFFFFF;
      text-transform: uppercase;
      font-size: 10px;
    }
    .img-title {
      position: absolute;
      bottom: 15px;
      left: 25px;
      padding: 5px;
      color: #FFFFFF;
      text-transform: uppercase;
      font-size: 12px;
    }
  }
  .block {
    padding: 15px 24px;
    overflow: hidden;
    height: 160px;
    .tahoma {
      margin-bottom: 10px;
      text-align: left;
    }
      .text-date{
          font-size: .75rem;
      }
    .roboto-1 {
      color: rgba(17, 17, 17, 0.5);
      text-align: left;
    }
  }
  .shadow {
    box-shadow: 0px -10px 28px 29px #ffffff;
    width: 100%;
    height: 1px;
    position: absolute;
    bottom: -5px;
  }
    
    @media screen and (max-width: 500px){
        & .block{
            & .text-date{
                font-size: .7rem;
            }
        }
    }
}
`
