import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Fade from 'react-reveal/Fade'
import {
  Container, NewsCard
} from '../Main/styles'
import {
  CustomLink
} from './styles'
import { useHistory, useParams } from 'react-router-dom'
import {Result, Image, Row, Col} from 'antd'

//Api
import {ArticlesClient, ArticlesData} from '../../api'

import noPhoto from '../../img/no-photo.svg'
import img_default from '../../img/image-default.jpg'




const SinglePassangers = () => {
  const history = useHistory()
  const {t} = useTranslation()
  const params = useParams()
  const {id} = params
  let lang = localStorage.getItem('language')||'ru'
  lang = lang[0].toUpperCase()+lang.substring(1)
  const [article, setArticle] = useState(null)
  const [articles, setArticles] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(()=>{
    setLoading(true)
    getData()
  }, [id])


  const getData = async ()=>{
    await ArticlesClient.getPassengerById(id)
      .then(result=>{

        if(result?.data){
          setArticle(result.data)
        }
      }).catch(()=>setLoading(false))

    await ArticlesClient.getPassengers()
      .then(result=>{
        if(result?.data?.content){
          setArticles(result.data.content)
        }
      })

    setLoading(false)
  }

  const getImage = (name)=>{
    if(name) return ArticlesData.getFiles(name)
    return noPhoto
  }


  if(loading){
    return ''
  }


  return id&&article ? (
    <>
      <Container style={{marginTop: 90, marginBottom: 0}}>
        <div className="breadcrumbs">
          <CustomLink className="roboto-1" to="/">{t('main')}</CustomLink> / <CustomLink className="roboto-1" to="/passengers">{t('passengers')}</CustomLink> / <span className="roboto-1 ">{article[`title${lang}`]}</span>
        </div>
      </Container>
      <Fade>
        <Container className="news-top-banner single">
          <div className='img-b position-absolute' style={{width: '100%', height: '100%'}}>
            <img style={{objectFit:'cover', width: '100%', height: '100%'}} src={getImage(article['images']?article['images'][0]?.name:'')} alt='' />
          </div>
          {/*<div className="news-bottom-banner-single">*/}
          {/*  <div className="tahoma date">{data[id].date?data[id].date:''}</div>*/}
          {/*</div>*/}
        </Container>

      </Fade>
      <Container className="single-content">
        <Fade bottom>
          <h2 className="tahoma title">{article[`title${lang}`]}</h2>
          <div dangerouslySetInnerHTML={{__html: article[`body${lang}`]}} style={{wordBreak: 'break-all'}}></div>
          <Image.PreviewGroup >
            <Row gutter={[16,16]} style={{marginTop: '20px'}}>
              {
                (article.images||[]).map(item=>{
                  return(
                    <Col xs={24} sm={12} md={12} lg={8} key={item.id} style={{height: '260px'}}>
                      <Image src={getImage(item.name)}  height={260} width={'100%'} />
                    </Col>
                  )
                })
              }
            </Row>
          </Image.PreviewGroup>
        </Fade>
      </Container>
      <Container>
        <Fade bottom>
          <Row gutter={[24,24]}>
            {
              articles.map((item, index)=>{
                if(index<3){
                  let imgSrc = (item.images||[]).map(itemImg=>ArticlesData.getFiles(itemImg.name))
                  // let date = moment(item.insDate).format('MMMM Do YYYY')
                  return(
                    <Col xs={24} sm={24} md={8} lg={8} key={index}>
                      <NewsCard onClick={()=>{
                        history.push({
                          pathname: `/passengers/${item.id}`,
                          state: {
                            title: item[`title${lang}`],
                            date: '',
                            image: imgSrc.length?imgSrc[0]:img_default
                          }
                        })
                      }}>
                        <div className="img">
                          <img className='position-absolute w-100 h-100' style={{objectFit: 'cover'}} src={imgSrc.length?imgSrc[0]:img_default} alt='' />
                          {/* <div className="category tahoma">Категория</div> */}
                        </div>
                        <div className="block">
                          {/*<div className="img-title tahoma text-date">{date}</div>*/}
                          <div className="tahoma">
                            {item[`title${lang}`]}
                          </div>
                          {/*<div className="roboto-1">*/}
                          {/*  {item[`title${lang}`]}*/}
                          {/*</div>*/}
                        </div>
                        <div className="shadow"/>
                      </NewsCard>
                    </Col>
                  )
                }
              })
            }


          </Row>
        </Fade>
      </Container>
    </>
  ):(
    <Result
      style={{paddingTop: 110, paddingBottom: 10}}
      status="404"
      title="404"
      subTitle={t('does not exist')}
    />
  )
}

export default SinglePassangers
