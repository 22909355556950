/* eslint-disable react/prop-types */
import React, {useState, useEffect, useRef} from 'react'
import { observer } from 'mobx-react'
import i18n from '../../translates/i18n'
import {themeSwitcher} from '../../stores/ThemeSwitcher'
import {partners} from '../../stores/Partners'
import {loginCheck} from '../../stores/LoginCheck'
import { useTranslation } from 'react-i18next'
import { useLocation, withRouter, useHistory } from 'react-router-dom'
import LoaderBlock from '../utils/LoaderBlock/LoaderBlock'
import {Button, Select, Menu, Dropdown, Form, Input, Checkbox, Space, Drawer, Divider} from 'antd'
import Fade from 'react-reveal/Fade'
import MaskedInput from 'antd-mask-input'
import InputMask from 'react-input-mask'
import repeat_icon from '../../img/repeat_icon.svg'
import ок from '../../img/ок.svg'
import {
  Container,
  CustomLink,
  Logo,
  CustomIcon,
  CustomModal,
  MoreButton,
  CustomButton
} from './styles'
import {
  CaretDownOutlined,
  ClockCircleOutlined,
  MenuOutlined
} from '@ant-design/icons'
import { LoginHome, ArticlesClient } from '../../api'
import Cookies from 'universal-cookie'
// import MenuContext from 'antd/lib/menu/MenuContext'

const { Option } = Select



const Header = observer(() => {
  const {t} = useTranslation()
  const location = useLocation()
  const inputEl = useRef()
  const history = useHistory()
  const [form] = Form.useForm()
  const [formReg] = Form.useForm()
  const [activeLang, setLang] = useState('')
  const [login, setLogin] = useState(false)
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [validatePhone, setValidatePhone] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [password, setPassword] = useState('')
  const [reg, setReg] = useState(false)
  const [regPin, setRegPin] = useState(false)
  const [count, setCount] = useState(0)
  const [pin, setPin] = useState('')
  const [errorPin, setErrorPin] = useState(false)
  const [loader, setLoader] = useState(false)
  const [repeatPin, setRepeatPin] = useState(false)
  const [newPassword, setNewPassword] = useState(false)
  const [passwordNew, setPasswordNew] = useState('')
  const [repeatPass, setRepeatPass] = useState('')
  const [access, setAccess] = useState(false)
  const [regUsername, setRegUsername] = useState('')
  const [errorMsg, setErrorMsg] = useState('')
  const [errorReg, setErrorReg] = useState(false)
  // const [currentParams, setCurrentParams] = useState()
  const [code13, setCode13] = useState(false)
  const [visible, setVisible] = useState(false)

  const cookies = new Cookies(null, { path: '/' })

  let lang = localStorage.getItem('language')||'ru'
  lang = lang[0].toUpperCase()+lang.substring(1)


  useEffect(() => {
    if(loginCheck.getLogin) {
      openModal()
    }
  }, [loginCheck.getLogin])


  useEffect(()=>{
    if(!partners.getPartners.length && !window.location.href.match('admin')){
      ArticlesClient.getPartners()
        .then(result=>{
          if(result?.data?.content){
            partners.setPartners(result?.data?.content)
          }
        })
    }
  }, [])


  useEffect(() => {
    if(window.location.search === '?login') {
      openModal()
    }
  }, [window.location.search])

  useEffect(() => {
    if((localStorage.getItem('login') === 'on' && cookies.get('token')) || (sessionStorage.getItem('login') === 'on' && sessionStorage.getItem('token'))) {
      setLogin(true)
    }else if(sessionStorage.getItem('refresh_token') && sessionStorage.getItem('username')) {
      LoginHome.refreshLogin(sessionStorage.getItem('refresh_token'), sessionStorage.getItem('username'))
        .then(({data}) => {
          sessionStorage.setItem('id', data.id)
          sessionStorage.setItem('refresh_token', data.data.refreshtoken)
          sessionStorage.setItem('token', data.data.token)
          sessionStorage.setItem('username', data.data.username)
          sessionStorage.setItem('login', 'on')
          setLogin(true)
        })
        .catch(e=>{
          console.log(e.response)
          if(e.response.status !== 401) {
            console.log('Ошибка: ', e.response)
            sessionStorage.removeItem('id')
            sessionStorage.removeItem('refresh_token')
            sessionStorage.removeItem('token')
            sessionStorage.removeItem('username')
            sessionStorage.removeItem('login')
            window.location = '/?login'
          }
        })
    }else if(cookies.get('refresh_token') && cookies.get('username')) {
      LoginHome.refreshLogin(cookies.get('refresh_token'), cookies.get('username'))
        .then(({data}) => {
          cookies.set('id', data.id)
          cookies.set('refresh_token', data.data.refreshtoken)
          cookies.set('token', data.data.token)
          cookies.set('username', data.data.username)


          localStorage.setItem('login', 'on')
          setLogin(true)
        })
        .catch(e=>{

          if(e.response.status !== 401) {

            cookies.remove('id')
            cookies.remove('refresh_token')
            cookies.remove('token')
            cookies.remove('username')


            localStorage.removeItem('login')
            window.location = '/?login'
          }
        })
    }
    let lang = localStorage.getItem('language') || 'ru'
    i18n.changeLanguage(lang).then()
    setLang(lang)
  }, [])

  useEffect(() => {
    const timer=setTimeout(() => {
      if(count === 0) {
        clearTimeout(timer)
        setErrorPin(false)
        setRepeatPin(true)
      }else{
        setCount(prevCount => prevCount - 1)
      }
    }, 1000)
    return () => clearTimeout(timer)
  }, [count])

  const menu = (
    <Menu>
      <Menu.Item key="0" onClick={()=>history.push('/cabinet')}>
        <span>{t('profile')}</span>
      </Menu.Item>
      <Menu.Item key="1" onClick={()=>{

        cookies.remove('id')
        cookies.remove('refresh_token')
        cookies.remove('token')
        cookies.remove('username')


        localStorage.removeItem('login', 'on')
        sessionStorage.removeItem('id')
        sessionStorage.removeItem('refresh_token')
        sessionStorage.removeItem('token')
        sessionStorage.removeItem('username')
        sessionStorage.removeItem('login')
        setLogin(false)
        history.push('/')
      }}>
        <span>{t('logout')}</span>
      </Menu.Item>
    </Menu>
  )

  const resetPassword = () => {
    if(phoneNumber === undefined) {
      setValidatePhone('error')
    }
    if(phoneNumber.indexOf('x') !== -1) {
      setValidatePhone('error')
    }
    if(phoneNumber.length === 0) {
      setValidatePhone('error')
    }else{
      setRegUsername(phoneNumber.slice(1,phoneNumber.length).split(' ').join(''))
      LoginHome.resetPass(phoneNumber.slice(1,phoneNumber.length).split(' ').join(''))
        .then(({data})=>{
          console.log(data)
          setRegPin(true)
          setCount(59)
        })
        .catch(e=>{
          console.log(e.response)
        })
    }
  }

  const handleChange = (value) => {
    localStorage.setItem('language', value)
    i18n.changeLanguage(value).then()
    setLang(value)
    if(window.location.pathname.match(/news|tenders|document/g)){
      window.location.reload()
    }
  }

  const openModal = () => {
    form.resetFields()
    setIsModalVisible(true)
    setRepeatPin(false)
    setRegPin(false)
    setErrorPin(false)
    setNewPassword(false)
    setAccess(false)
  }
  const handleCancel = () => {
    setIsModalVisible(false)
    setRegPin(false)
    setReg(false)
    setRepeatPin(false)
    setErrorPin(false)
    setNewPassword(false)
    setAccess(false)
    setCode13(false)
    loginCheck.setLogin(false)
  }
  const onFinish = (values) => {
    if(values.phoneNumber === undefined) {
      setValidatePhone('error')
    }
    if(values.phoneNumber.indexOf('x') !== -1) {
      setValidatePhone('error')
    }
    if(values.phoneNumber.length === 0) {
      setValidatePhone('error')
    }else{
      setValidatePhone('')
      if(values.remember) {
        LoginHome.singIn(values.password,values.phoneNumber.slice(1,values.phoneNumber.length).split(' ').join(''))
          .then(({data})=>{
            cookies.set('id', data.data.id)
            cookies.set('refresh_token', data.data.refreshtoken)
            cookies.set('token', data.data.token)
            cookies.set('username', data.data.username)

            localStorage.setItem('login', 'on')
            setLogin(true)
            if(loginCheck.getCip) {
              loginCheck.setCip(false)
              history.push('/cip')
            }else{
              history.push('/cabinet')
            }
            handleCancel()
          })
          .catch(e=>{

            if(e.response.data.message.text) {
              setErrorMsg(e.response.data.message.text)
              setErrorReg(true)
            }
          })
      }else{
        LoginHome.singIn(values.password,values.phoneNumber.slice(1,values.phoneNumber.length).split(' ').join(''))
          .then(({data})=>{
            sessionStorage.setItem('id', data.data.id)
            sessionStorage.setItem('refresh_token', data.data.refreshtoken)
            sessionStorage.setItem('token', data.data.token)
            sessionStorage.setItem('username', data.data.username)
            sessionStorage.setItem('login', 'on')
            setLogin(true)
            if(loginCheck.getCip) {
              loginCheck.setCip(false)
              history.push('/cip')
            }else{
              history.push('/cabinet')
            }
            handleCancel()
          })
          .catch(e=>{
            console.log(e.response)
            if(e.response.data.message.text) {
              setErrorMsg(e.response.data.message.text)
              setErrorReg(true)
            }
          })
      }
    }
  }

  const onFinishReg = (values) => {
    if(values.phoneNumber === undefined) {
      setValidatePhone('error')
    }
    if(values.phoneNumber.indexOf('x') !== -1) {
      setValidatePhone('error')
    }
    if(values.phoneNumber.length === 0) {
      setValidatePhone('error')
    }else{
      console.log('onFinishReg:', values)
      const params = new URLSearchParams()
      params.append('username', values.phoneNumber.slice(1,values.phoneNumber.length).split(' ').join(''))
      // setCurrentParams(values.phoneNumber.slice(1,values.phoneNumber.length).split(' ').join(''))
      LoginHome.reg(params)
        .then(({data})=>{
          setRegUsername(data.data.username)
          setValidatePhone('')
          setReg(false)
          setRegPin(true)
          setCount(59)
          setPin('')
          inputEl.current.focus()
        })
        .catch(e=>{
          console.log(e.response.data)
          if(e.response.data.message.text) {
            setErrorMsg(e.response.data.message.text)
            setErrorReg(true)
          }
        })
    }
  }

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo)
  }
  const onFinishCreatePass = (values) => {
    console.log('onFinishCreatePass:', values)
    if(values.password === values.repeatPass) {
      LoginHome.setUserPass(values.repeatPass, regUsername)
        .then(()=>{
          setNewPassword(false)
          setAccess(true)
        })
        .catch(e=>{
          console.log(e.response)
        })
    }
  }

  const pay = () => {
    if(pin.length === 6) {
      LoginHome.validate(pin, regUsername)
        .then(()=>{
          setLoader(true)
          setErrorPin(false)
          setLoader(false)
          setRegPin(false)
          setNewPassword(true)
        })
        .catch(e=>{
          setErrorPin(true)
          console.log(e.response)
          if(e.response.data.message.text) {
            if(e.response.data.message.code === 13) {
              setRepeatPin(true)
              setCode13(true)
            }
            setErrorMsg(e.response.data.message.text)
            setPin('')
            inputEl.current.focus()
          }
        })
    }else{
      setErrorPin(true)
    }
  }
  const sendRepeat = () => {
    setRepeatPin(false)
    setCode13(true)
    setErrorPin(false)
    setErrorMsg('')
    const params = new URLSearchParams()
    params.append('username', regUsername)
    LoginHome.reg(params)
      .then(({data})=>{
        setRegUsername(data.data.username)
        setValidatePhone('')
        setCount(59)
        setPin('')
        inputEl.current.focus()
      })
      .catch(e=>{
        console.log(e.response.data)
        if(e.response.data.message.text) {
          setErrorMsg(e.response.data.message.text)
          setErrorReg(true)
        }
      })
  }

  const showDrawer = () => {
    setVisible(true)
  }

  const onClose = () => {
    setVisible(false)
  }

  const about_menu = (
    <Menu>
      <Menu.Item>
        <CustomLink to="/about/history" className={`tahoma ${location.pathname === '/about/history' ? 'active' : ''}`} onClick={()=>{onClose()}}>{t('history.title')}</CustomLink>
      </Menu.Item>
    </Menu>
  )

  const partenr_menu = (
    <Menu>
      {
        partners.getPartners.map(item=>{
          return(
            <Menu.Item key={item.id}>
              <CustomLink to={`/partners/${item.id}`} className={`tahoma ${location.pathname === `/partners/${item.id}` ? 'active' : ''}`} onClick={()=>{onClose()}}>{item[`title${lang}`]}</CustomLink>
            </Menu.Item>
          )
        })
      }
      {/*<Menu.Item>*/}
      {/*  <CustomLink to="/partners/1" className={`tahoma ${location.pathname === '/partners/1' ? 'active' : ''}`} onClick={()=>{onClose()}}>{t('parters.cat-title1')}</CustomLink>*/}
      {/*</Menu.Item>*/}
      {/*<Menu.Item>*/}
      {/*  <CustomLink to="/partners/2" className={`tahoma ${location.pathname === '/partners/2' ? 'active' : ''}`} onClick={()=>{onClose()}}>{t('parters.cat-title2')}</CustomLink>*/}
      {/*</Menu.Item>*/}
      {/*<Menu.Item>*/}
      {/*  <CustomLink to="/partners/3" className={`tahoma ${location.pathname === '/partners/3' ? 'active' : ''}`} onClick={()=>{onClose()}}>{t('parters.cat-title3')}</CustomLink>*/}
      {/*</Menu.Item>*/}
      {/*<Menu.Item>*/}
      {/*  <CustomLink to="/partners/4" className={`tahoma ${location.pathname === '/partners/4' ? 'active' : ''}`} onClick={()=>{onClose()}}>{t('parters.cat-title4')}</CustomLink>*/}
      {/*</Menu.Item>*/}
      {/*<Menu.Item>*/}

      {/*  <CustomLink to="/partners/5" className={`tahoma ${location.pathname === '/partners/5' ? 'active' : ''}`} onClick={()=>{onClose()}}>{t('parters.cat-title5')}</CustomLink>*/}
      {/*</Menu.Item>*/}
    </Menu>
  )

  const linkDropDown = {
    display: 'flex',
    alignItems: 'center'
  }

  return location.pathname.indexOf('admin-airport') === 1 ? undefined:(
    <Container>
      {
        window.outerWidth < 769 ? (
          <div className="mobile-nav">
            <CustomLink to="/"><Logo/></CustomLink>
            <div className="profile-block">
              <Space>
                <Button type="text" className="tahoma" onClick={()=>themeSwitcher.change()}>AA</Button>
                <Select className="tahoma" value={activeLang} bordered={false} suffixIcon={<CaretDownOutlined />} onChange={handleChange}>
                  <Option value="uz">O'z</Option>
                  <Option value="ru">Ru</Option>
                  <Option value="en">En</Option>
                </Select>
                <Button type="text" className="tahoma custom-btn" onClick={showDrawer}>
                  <MenuOutlined className="custom"/>
                </Button>
              </Space>
            </div>
            <Drawer title={
              login ? (
                <a className="ant-dropdown-link" onClick={e =>{
                  e.preventDefault()
                  history.push('/cabinet')
                  onClose()
                }}>
                  <CustomIcon />
                </a>
              ):(
                <a className="ant-dropdown-link" onClick={e => {
                  e.preventDefault()
                  openModal()
                  onClose()
                }}>
                  <CustomIcon />
                </a>
              )
            } placement="right" onClose={onClose} visible={visible}>
              <CustomLink to="/" className={`tahoma ${location.pathname === '/' ? 'active' : ''}`} onClick={()=>{onClose()}}>{t('main')}</CustomLink>
              <Divider style={{margin: '10px 0'}}/>
              <CustomLink to="/partners" className={`tahoma ${location.pathname === '/partners' ? 'active' : ''}`} onClick={()=>{onClose()}}>{t('partners')}</CustomLink>
              <Divider style={{margin: '10px 0'}}/>
              <CustomLink to="/passengers" className={`tahoma ${location.pathname === '/passengers' ? 'active' : ''}`} onClick={()=>{onClose()}}>{t('passengers')}</CustomLink>
              <Divider style={{margin: '10px 0'}}/>
              <CustomLink to="/certificates" className={`tahoma ${location.pathname === '/certificates' ? 'active' : ''}`} onClick={()=>{onClose()}}>{t('certificates')}</CustomLink>
              <Divider style={{margin: '10px 0'}}/>
              <CustomLink to="/about" className={`tahoma ${location.pathname === '/about'||location.pathname === '/about/history' ? 'active' : ''}`} onClick={()=>{onClose()}}>{t('about us')}</CustomLink>
              <Divider style={{margin: '10px 0'}}/>
              <CustomLink to="/about/history" className={`tahoma ${location.pathname === '/about/history' ? 'active' : ''}`} onClick={()=>{onClose()}}>{t('history.title')}</CustomLink>
              <Divider style={{margin: '10px 0'}}/>
              <CustomLink to="/documents" className={`tahoma ${location.pathname === '/documents' ? 'active' : ''}`} onClick={()=>{onClose()}}>{t('document.breadcrumbs')}</CustomLink>
              <Divider style={{margin: '10px 0'}}/>
              <CustomLink to="/tenders" className={`tahoma ${location.pathname === '/tenders' ? 'active' : ''}`} onClick={()=>{onClose()}}>{t('tab tenders')}</CustomLink>
            </Drawer>
          </div>
        ):(
          <>
            <div className="nav">
              <CustomLink to="/"><Logo/></CustomLink>
              <CustomLink to="/" className={`tahoma ${location.pathname === '/' ? 'active' : ''}`}>{t('main')}</CustomLink>

              <Dropdown overlay={partenr_menu} placement="bottomCenter" >
                <CustomLink to="/partners" style={linkDropDown} className={`tahoma ${location.pathname === '/partners' ? 'active' : ''}`}>{t('partners')} <CaretDownOutlined style={{marginLeft: '5px'}}/></CustomLink>
              </Dropdown>
              <CustomLink to="/passengers" className={`tahoma ${location.pathname === '/passengers' ? 'active' : ''}`}>{t('passengers')}</CustomLink>
              <CustomLink to="/certificates" className={`tahoma ${location.pathname === '/certificates' ? 'active' : ''}`}>{t('certificates')}</CustomLink>
              <Dropdown overlay={about_menu} placement="bottomCenter" >
                <CustomLink to="/about" style={linkDropDown} className={`tahoma ${location.pathname === '/about' ? 'active' : ''}`}>{t('about us')} <CaretDownOutlined style={{marginLeft: '5px'}}/></CustomLink>
              </Dropdown>
              <CustomLink to="/documents" className={`tahoma ${location.pathname === '/documents' ? 'active' : ''}`}>{t('document.breadcrumbs')}</CustomLink>
              <CustomLink to="/tenders" className={`tahoma ${location.pathname === '/tenders' ? 'active' : ''}`} onClick={()=>{onClose()}}>{t('tab tenders')}</CustomLink>
            </div>
            <div className="profile-block">
              <Select className="tahoma" value={activeLang} bordered={false} suffixIcon={<CaretDownOutlined />} onChange={handleChange}>
                <Option value="uz">O'z</Option>
                <Option value="ru">Ru</Option>
                <Option value="en">En</Option>
              </Select>
              <Button type="text" className="tahoma" onClick={()=>themeSwitcher.change()}>AA</Button>
              {
                login ? (
                  <Dropdown overlay={menu} trigger={['click']}>
                    <a className="ant-dropdown-link" onClick={e =>e.preventDefault()}>
                      <CustomIcon />
                    </a>
                  </Dropdown>
                ):(
                  <a className="ant-dropdown-link" onClick={e => {
                    e.preventDefault()
                    openModal()
                  }}>
                    <CustomIcon />
                  </a>
                )
              }
            </div>
          </>
        )
      }

      <CustomModal maskClosable={false} className={access ? 'access-modal':''} title={false} visible={isModalVisible} footer={false} onCancel={()=>handleCancel()}>
        {
          reg ? (
            <Fade>
              <div>
                <div className="tahoma">{t('reg title')}</div>
                <Form
                  form={formReg}
                  layout="vertical"
                  name="reg"
                  onFinish={onFinishReg}
                  onFinishFailed={onFinishFailed}
                >
                  <Form.Item
                    className="roboto-1"
                    label={t('phone number')}
                    name="phoneNumber"
                    validateStatus={validatePhone}
                  >
                    <MaskedInput mask="\+\9\9\8 11 111 11 11" autoComplete="new-password" type="tel" placeholderChar="x" onChange={(e)=>{setPhoneNumber(e.target.value); setErrorReg(false)}}/>
                  </Form.Item>
                  <div style={{position: 'relative',overflow: 'hidden'}}>
                    <Form.Item
                      className="submit-btn"
                    >
                      <Button style={{marginTop: 0}} disabled={phoneNumber ? false : true} type="primary" className="roboto-2" htmlType="submit">
                        {t('reg')}
                      </Button>
                    </Form.Item>
                    <div className={`roboto-2 error-reg ${errorReg ? 'active' : ''}`}>{errorMsg}</div>
                  </div>
                  <div className="new-user roboto-2" onClick={()=>{
                    setPhoneNumber('')
                    formReg.resetFields()
                    setReg(false)
                  }}>{t('exist user')}</div>
                </Form>
              </div>
            </Fade>
          ): regPin ? (
            <Fade>
              <div className="sms-modal" style={{textAlign: 'center'}}>
                <LoaderBlock loading={loader}/>
                <div className="tahoma">{t('verify code')}</div>
                {/* <p className="roboto-1 phone">{t('send to')}</p> */}
                <InputMask
                  className={`roboto-1 ${errorPin ? 'error' : ''}`}
                  mask="999999"
                  alwaysShowMask={true}
                  maskChar = ''
                  type="tel"
                  value={pin}
                  onChange={(e)=>{
                    setErrorPin(false)
                    setPin(e.target.value)
                  }}
                >
                  {(inputProps) => <Input ref={inputEl} maxLength='6' {...inputProps} style={{background: 'transparent', borderRadius: 0, padding: 0}}/>}
                </InputMask>
                <div className={`underline-group ${errorPin ? 'error':''}`}>
                  <div className="underline"></div>
                  <div className="underline"></div>
                  <div className="underline"></div>
                  <div className="underline"></div>
                  <div className="underline"></div>
                  <div className="underline"></div>
                </div>
                <div className="timer roboto-1" style={repeatPin ? {opacity: 0}:{opacity: 1}}><ClockCircleOutlined /> 00:{count < 10 ? '0' + count:count}</div>
                <div className={`error-msg roboto-1 ${errorPin ? 'active':''}`}>{errorMsg ? errorMsg : t('pin error')}</div>
                <div className={`repeat-msg roboto-1 ${repeatPin ? 'active':''}`} style={code13 ? {top: '60%'}:undefined} onClick={()=>repeatPin ? sendRepeat():undefined}><img src={repeat_icon}/> {t('repeat send')}</div>
                <Space size={window.outerWidth > 564 ? 20 : 10} direction={window.outerWidth > 564 ? 'horizontal':'vertical'}>
                  <MoreButton className="info-btn" style={{padding: '11px 50px'}} onClick={()=>{
                    setRegPin(false)
                    setReg(false)
                    setRepeatPin(false)
                    setErrorPin(false)
                  }}>{t('go back')}</MoreButton>
                  <CustomButton className="info-btn" disabled={pin.length !== 6} style={{padding: '11px 50px'}} onClick={()=>pay()}>{t('accept')}</CustomButton>
                </Space>
              </div>
            </Fade>
          ): newPassword ?(
            <Fade>
              <div className="tahoma">{t('create pass')}</div>
              <Form
                layout="vertical"
                name="createPass"
                onFinish={onFinishCreatePass}
                onFinishFailed={onFinishFailed}
              >
                <Form.Item
                  className="roboto-1"
                  name="password"
                  label={t('password label')}
                  rules={[
                    {
                      required: true,
                      message: 'Please input your password!'
                    }
                  ]}
                >
                  <Input.Password placeholder={t('password label')} autoComplete="new-password" onChange={(e)=>setPasswordNew(e.target.value)}/>
                </Form.Item>

                <Form.Item
                  className="roboto-1"
                  name="repeatPass"
                  label={t('repeat pass')}
                  rules={[
                    {
                      required: true,
                      message: 'Please input your password!'
                    }
                  ]}
                >
                  <Input.Password placeholder={t('repeat pass')} autoComplete="new-password" onChange={(e)=>setRepeatPass(e.target.value)}/>
                </Form.Item>
                <Form.Item
                  className="submit-btn"
                >
                  <Button disabled={passwordNew && repeatPass && passwordNew === repeatPass ? false : true} type="primary" className="roboto-2" htmlType="submit">
                    {t('login')}
                  </Button>
                </Form.Item>
              </Form>
            </Fade>
          ): access ?(
            <Fade>
              <div className="access-block-login">
                <img src={ок} alt="ok"/>
                <h3 className="roboto-3">{t('acc created', {number: `+${regUsername[0]}${regUsername[1]}${regUsername[2]} ${regUsername[3]}${regUsername[4]} ${regUsername[5]}${regUsername[6]}${regUsername[7]} ${regUsername[8]}${regUsername[9]} ${regUsername[10]}${regUsername[11]}`})}</h3>
                <CustomButton style={{padding: '11px 120px'}} onClick={()=>handleCancel()}>{t('close')}</CustomButton>
              </div>
            </Fade>
          ):(
            <Fade>
              <div className="tahoma">{t('login title')}</div>
              <Form
                layout="vertical"
                form={form}
                name="login"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
              >
                <Form.Item
                  className="roboto-1"
                  label={t('phone number')}
                  name="phoneNumber"
                  validateStatus={validatePhone}
                >
                  <MaskedInput mask="\+\9\9\8 11 111 11 11" autoComplete="new-password" type="tel" placeholderChar="x" onChange={(e)=>{
                    setValidatePhone(false)
                    setPhoneNumber(e.target.value)
                    setErrorReg(false)
                  }}/>
                </Form.Item>

                <Form.Item
                  className="roboto-1"
                  labels={t('password label')}
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: 'Please input your password!'
                    }
                  ]}
                >
                  <Input.Password autoComplete="new-password" onChange={(e)=>{setPassword(e.target.value); setErrorReg(false)}}/>
                </Form.Item>
                <div className="flex-block">
                  <Form.Item
                    className="roboto-1"
                    name="remember"
                    valuePropName="checked"
                  >
                    <Checkbox>{t('remember me')}</Checkbox>
                  </Form.Item>
                  <div className="roboto-1 forgot" onClick={()=>resetPassword()}>{t('forgot pass')}</div>
                </div>
                <div style={{position: 'relative',overflow: 'hidden'}}>
                  <Form.Item
                    className="submit-btn"
                  >
                    <Button disabled={phoneNumber && password ? false : true} type="primary" className="roboto-2" htmlType="submit">
                      {t('login')}
                    </Button>
                  </Form.Item>
                  <div className={`roboto-2 error-reg logIn ${errorReg ? 'active' : ''}`}>{errorMsg}</div>
                </div>
                <div className="new-user roboto-2" onClick={()=>{
                  setPhoneNumber('')
                  form.resetFields()
                  setReg(true)
                }}>{t('new user')}</div>
              </Form>
            </Fade>
          )
        }
      </CustomModal>
    </Container>
  )
})

export default withRouter(Header)
