import { makeAutoObservable } from 'mobx'

// Model the application state.
class ThemeSwitcher {
    theme = {
      val: true
    }

    constructor() {
      makeAutoObservable(this)
    }

    change() {
      this.theme.val = !this.theme.val
    }
}

export const themeSwitcher = new ThemeSwitcher()
