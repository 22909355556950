import styled from 'styled-components'
import {Link} from 'react-router-dom'
import logo from '../../img/logo-v2.svg'
import Shape from '../../img/Shape.svg'
import CombinedShape from '../../img/Combined-Shape.svg'
import Vector from '../../img/Vector.svg'
import apple from '../../img/Appstore.svg'
import google from '../../img/Google Play.svg'
import Shape_hover from '../../img/Shape_hover.svg'
import CombinedShape_hover from '../../img/Combined-Shape_hover.svg'
import Vector_hover from '../../img/Vector_hover.svg'

export const Container = styled.div`
&&& {
  position:absolute;
  left:0;
  bottom:0;
  right:0;
  padding: 40px 10%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  background: #ffffff;
  border-top: 1px solid #dddddd;
  @media (min-width: 768px) and (max-width: 1025px) {
    padding: 20px 4%;
  }
  @media (max-width: 768px) {
    flex-wrap: wrap-reverse;
    padding: 30px 6%;
    /* position:static; */
    bottom: -150vw;
  }
  .left {
    @media (max-width: 768px) {
      flex: 0 0 100%;
      max-width: 100%;
      text-align: center;
      margin-top: 15px;
    }
    .roboto-1 {
      color: rgba(17, 17, 17, 1);
    }
  }
  .center {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    @media (max-width: 768px) {
      flex: 0 0 100%;
      max-width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .first {
      margin-right: 120px;
      @media (max-width: 768px) {
        margin-right: 0px;
      }
    }
    .second {
      a {
        display: block;
        color: rgba(17, 17, 17, 0.5);
        font-size: ${props => props.theme.val ? '16px' : '18px'};
        line-height: 24px;
        &.active {
          color: #111111;
        }
        &:hover,
        &:focus {
          color: #111111;
        }
      }
    }
  }
  .right {
    @media (max-width: 768px) {
      flex: 0 0 100%;
      max-width: 100%;
      margin-bottom: 15px;
    }
    .top {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      margin-bottom: 20px;
      @media (max-width: 768px) {
        justify-content: center;
      }
      a {
        display: block;
        width: 32px;
        height: 32px;
        border-radius: 100px;
        margin-right: 25px;
        transition: transform 300ms ease;
        overflow: hidden;
        position: relative;
        &:last-child{
          margin-right: 0;
        }
        div {
          display: block;
          width: 32px;
          height: 32px;
          transition: transform 300ms ease;
          &.tg {
            background: url(${Shape}) center/contain no-repeat;
          }
          &.tg_hover {
            background: url(${Shape_hover}) center/contain no-repeat;
            transform: translateY(-70px);
          }
          &.ins {
            background: url(${CombinedShape}) center/contain no-repeat;
          }
          &.ins_hover {
            background: url(${CombinedShape_hover}) center/contain no-repeat;
            transform: translateY(-70px);
          }
          &.fb {
            background: url(${Vector}) center/contain no-repeat;
          }
          &.fb_hover {
            background: url(${Vector_hover}) center/contain no-repeat;
            transform: translateY(-70px);
          }
        }
        &:hover .tg {
          transform: translateY(35px);
        }
        &:hover .tg_hover {
          transform: translateY(-32px);
        }
        &:hover .ins {
          transform: translateY(35px);
        }
        &:hover .ins_hover {
          transform: translateY(-32px);
        }
        &:hover .fb {
          transform: translateY(35px);
        }
        &:hover .fb_hover {
          transform: translateY(-32px);
        }
      }
    }
    .bottom {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      @media (max-width: 768px) {
        justify-content: center;
      }
      a {
        display: block;
        width: 94px;
        height: 32px;
        border-radius: 6px;
        transition: all 300ms ease;
        margin-right: 16px;
        &:hover {
          transform: translate(2px, -2px);
          box-shadow:-2px 2px 10px 0px #111111;
        }
        &:last-child {
          margin-right: 0;
        }
        &.apple {
          background: url(${apple}) center/cover no-repeat;
        }
        &.google {
          background: url(${google}) center/cover no-repeat;
        }
      }
    }
  }
}
`
export const Logo = styled.div`
  &&& {
    width: 270px;
    height: 80px;
    background: url(${logo}) left/contain no-repeat;
    margin-bottom: 10px;
    margin-right: 5px;
    @media (max-width: 1025px) {
      width: 180px;
    }
    @media (max-width: 768px) {
      margin: 0px auto 15px;
      width: 180px;
    }
  }
`
export const CustomLink = styled(Link)`
  &&& {
    display: block;
    color: rgba(17, 17, 17, 0.5);
    font-size: ${props => props.theme.val ? '16px' : '18px'};
    line-height: 24px;
    @media (max-width: 768px) {
      margin-bottom: 10px;
    }
    &.active {
      color: #111111;
    }
    &:hover,
    &:focus {
      color: #111111;
    }
  }
`
