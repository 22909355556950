import styled from 'styled-components'
import {Link} from 'react-router-dom'
import user from '../../img/user-icon.svg'
import logo from '../../img/logo-v2.svg'
import {Modal,Button} from 'antd'

export const CustomModal = styled(Modal)`
&&& {
  width: 500px !important;
  .error-reg {
    background: #FF2B6C;
    border: 1px solid #FF2B6C;
    padding: 11px 0px;
    width: 100%;
    height: auto;
    margin-top: 20px;
    color: #FFFFFF;
    font-size: 16px;
    border-radius: 10px;
    text-align: center;
    position: absolute;
    bottom: 24px;
    transform: translateY(-50px);
    transition: transform 300ms ease-in-out;
    &.logIn {
      transform: translateY(-72px);
    }
    &.active {
      transform: translateY(0px);
    }
  }
  .ant-modal-content {
    background: #FFFFFF;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.0952251);
    border-radius: 20px;
  }
  .ant-modal-body {
    padding: 40px 60px 30px;
    @media (max-width: 768px) {
      padding: 40px 40px 30px;
    }
  }
  .tahoma {
    font-size: 40px;
    line-height: 48px;
    @media (max-width: 768px) {
      font-size: 26px;
      line-height: 38px;
    }
    color: #111111;
    margin-bottom: 25px;
  }
  label {
    font-size: 14px;
    line-height: 16px;
    color: #5C5C5C;
  }
  .ant-input {
    background: #F7F7F7;
    border-radius: 10px;
    padding: 10px 12px;
    &.error {
      color: #ff1818;
    }
  }
  .ant-input-password {
    background: #F7F7F7;
    border-radius: 10px;
    padding: 10px 12px;
    .ant-input {
      background: #F7F7F7;
      border-radius: 0;
      padding: 0;
    }
  }
  .ant-form-item-required {
    &:before {
      content: none;
    }
  }
  .submit-btn .ant-btn{
    background: #1DC41F;
    border-radius: 10px;
    border-color: #1DC41F;
    padding: 11px 0px;
    width: 100%;
    height: auto;
    margin-top: 20px;
    color: #FFFFFF;
    font-size: 16px;
    &[disabled] {
      opacity: 0.4;
    }
  }
  .new-user {
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #0060BB;
    cursor: pointer;
    transition: color 300ms ease;
    &:hover {
      color: #3379bb;
    }
  }
  .flex-block {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .ant-row {
      margin: 0px;
    }
    .ant-checkbox-wrapper {
      font-size: 16px;
      color: #A3A3A4;
      transition: color 300ms ease;
      &:hover {
        color: #111111;
      }
    }
    .forgot {
      font-size: 16px;
      color: #A3A3A4;
      cursor: pointer;
      transition: color 300ms ease;
      &:hover {
        color: #111111;
      }
    }
  }
}
`

export const Container = styled.div`
&&& {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  top: 0;
  width: 100%;
  background: #ffffff;
  padding: 15px 6%;
  box-shadow: 0px 4px 4px rgba(17, 17, 17, 0.25);
  z-index: 100;
  @media (max-width: 1025px) {
    padding: 10px 4%;
  }
  @media (max-width: 768px) {
    padding: 10px 2%;
  }
  .nav {
    display: flex;
    align-items: center;
  }
  .profile-block {
    display: flex;
    align-items: center;
    .ant-btn {
      font-size: ${props => props.theme.val ? '16px' : '18px'};
    }
    .ant-select {
      font-size: ${props => props.theme.val ? '16px' : '18px'};
    }
    .ant-select-arrow {
      font-size: 12px;
      top: 50%;
    }
    .ant-dropdown-link {
      margin-left: 12px;
    }
  }
  .mobile-nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    .custom {
      font-size: 21px;
    }
    .ant-btn.custom-btn {
      border: 1px solid rgba(17,17,17,.08);
      padding: 5px 10px;
      border-radius: 4px;
    }
  }
}
`

export const CustomLink = styled(Link)`
  &&& {
    margin-right: 40px;
    color: rgba(17, 17, 17, 0.5);
    font-size: ${props => props.theme.val ? '16px' : '18px'};
    line-height: 24px;
    @media (max-width: 1400px) {
      margin-right: 20px;
    }
    &.active {
      color: #111111;
    }
    &:hover,
    &:focus {
      color: #111111;
    }
    @media (max-width: 768px) {
      display: block;
      margin-right: 0;
      text-align: center;
    }
  }
`

export const Logo = styled.div`
  &&& {
    width: 106px;
    height: 32px;
    background: url(${logo}) center/contain no-repeat;
    @media (max-width: 1025px) {
      width: 90px;
      height: 45px;
    }
    @media (max-width: 768px) {
      width: 90px;
      height: 45px;
    }
  }
`
export const CustomIcon = styled.div`
&&& {
  background: url(${user}) center/20px no-repeat #F7F7F7;
  border-radius: 100px;
  width: 40px;
  height: 40px;
}
`
export const MoreButton = styled(Button)`
&&& {
  border-color: rgba(163, 163, 164, 1);
  border-radius: 10px;
  font-size: ${props => props.theme.val ? '16px' : '18px'};
  color: rgba(163, 163, 164, 1);
  padding: 11px 26px;
  height: auto;
  &:hover {
    color: #000000;
    border-color: #000000;
  }
}
`
export const CustomButton = styled(Button)`
&&& {
  background: #1DC41F;
  border-color: #1DC41F;
  border-radius: 10px;
  font-size: ${props => props.theme.val ? '16px' : '18px'};
  color: rgba(255, 255, 255, 1);
  padding: 11px 26px;
  height: auto;
  &[disabled] {
    opacity: 0.4;
  }
  &:hover {
    background: #179e19;
    border-color: #179e19;
  }
}
`
