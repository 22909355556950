import { makeAutoObservable, toJS } from 'mobx'


// Model the application state.
class Partners {

  partners = []

  constructor() {
    makeAutoObservable(this)
  }

  setPartners(data) {
    this.partners = data
  }


  get getPartners() {
    return toJS(this.partners)
  }

}

export const partners = new Partners()
