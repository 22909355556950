import React, {useState, useEffect} from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const StyledLoader = styled.div`
&&& {
  position: absolute;
  height: 100%;
  width: 100%;
  background: rgba(255,255,255,0.7);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 20px;
  display: ${props => props.loading ? 'block':'none'}
}
`

const LoaderBlock = (props) => {
  const [state, setstate] = useState(false)
  useEffect(() => {
    const timer = setTimeout(() => {
      setstate(true)
    }, 500)
    return () => {
      clearTimeout(timer)
      setstate(false)
    }
  }, [])

  return (
    <StyledLoader loading={props.loading ? 1 : undefined} style={state ? {opacity: 1, zIndex: 1001}:{opacity: 0}}>
      <svg xmlns="http://www.w3.org/2000/svg" className="loader" viewBox="0 0 120 120">
        <circle cx="60" cy="60" r="60" fill="#0060bb"/>
        <g className="clouds">
          <path fill="#FFF" d="M193.61 76.4c-.86 0-1.7.1-2.54.3a16.18 16.18 0 0 0-20.2-12.34 18.09 18.09 0 0 0-34.15 5.9 13.91 13.91 0 0 0 1.5 27.74h55.4v-.07a10.78 10.78 0 1 0 0-21.53zm0 18.3h-55.37a10.61 10.61 0 1 1 0-21.23h1.65v-1.62A14.79 14.79 0 0 1 168.44 67l.6 1.52 1.53-.6c1.49-.58 3.07-.88 4.66-.87 6.77.04 12.37 5.3 12.83 12.05l.11 2.44 2.2-1.05a7.47 7.47 0 1 1 3.24 14.2z"/>
          <path fill="#FFF" d="M311.19 78.57a12.94 12.94 0 0 0-14.8-12.35 15.58 15.58 0 0 0-29.54-4.85 12.46 12.46 0 0 0-17.12 11.52v.7A12.44 12.44 0 0 0 253.1 98h53.6l.09-.02a10.24 10.24 0 0 0 4.4-19.41zm-4.4 15.98h-53.6a9.14 9.14 0 0 1-1.33-18.18l1.61-.25-.3-1.61a9.16 9.16 0 0 1 8.98-10.78 9.2 9.2 0 0 1 4.69 1.38l1.75 1.05.68-2.23a12.3 12.3 0 0 1 23.94 3.94v2.37l2.17-.68c.93-.3 1.91-.46 2.9-.46a9.7 9.7 0 0 1 9.6 9.71v.58l-.08 1.25 1.2.4a6.94 6.94 0 0 1-2.21 13.51z"/>
          <path fill="#FFF" d="M191.54 63.46h55.39v-.04a10.78 10.78 0 0 0 0-21.53c-.86 0-1.72.09-2.56.28a16.18 16.18 0 0 0-20.2-12.35 18.09 18.09 0 0 0-34.15 5.9 13.91 13.91 0 0 0 1.52 27.74zm.02-24.52l1.59-.01V37.3a14.8 14.8 0 0 1 28.55-4.85l.6 1.52 1.52-.58a12.82 12.82 0 0 1 17.5 11.18l.17 2.42 2.2-1.05a7.47 7.47 0 1 1 3.23 14.22h-55.36a10.61 10.61 0 1 1 0-21.23z"/>
          <path fill="#FFF" d="M381.54 44.04a12.94 12.94 0 0 0-14.8-12.36 15.6 15.6 0 0 0-29.52-4.85 12.43 12.43 0 0 0-17.12 11.52v.7a12.43 12.43 0 0 0 3.32 24.4h53.72a10.23 10.23 0 0 0 4.4-19.4zm-4.49 15.99h-53.6a9.16 9.16 0 0 1-1.33-18.2l1.7-.25-.32-1.62a9.16 9.16 0 0 1 8.96-10.76c1.66.02 3.28.5 4.7 1.36l1.7 1 .65-1.95a12.3 12.3 0 0 1 23.95 3.78v2.38l2.19-.68a9.61 9.61 0 0 1 12.5 9.18v.58l-.08 1.24 1.2.4a6.94 6.94 0 0 1-2.22 13.54z"/>
        </g>
        <g className="plane">
          <path fill="#0060bb" d="M106.5 80H30.88c-3.96-.42-7.33-3.02-10.05-7.73a60.2 60.2 0 0 1-5.5-15.48l-1.83-7.92h16.3l1.67 1.2c2 1.42 3.8 3.12 5.34 5.03.56.66 1.17 1.27 1.83 1.84h2.75L35.16 40h22l16.63 16.94h13.86l.72.16c7.72 1.84 11.53 3.98 13.2 7.63a58.95 58.95 0 0 1 2.51 6.95L106.5 80z"/>
          <path fill="#FFF" d="M100.32 75.42H31.36c-2.44-.26-4.58-2.03-6.56-5.5a56.83 56.83 0 0 1-4.96-14.25l-.55-2.27h9l.48.35a22.56 22.56 0 0 1 4.42 4.16 24.93 24.93 0 0 0 3.67 3.67h11l-6.2-16.96h13.48l16.63 16.94h15.48c5.98 1.37 9.17 2.93 10.07 5.08.89 2.08 1.65 4.2 2.29 6.38l.71 2.4zm-68.65-3.67h63.66c-.39-1.2-.84-2.4-1.37-3.55 0 0-.72-1.43-7.34-2.97H70.23L53.6 48.28H47l6.22 16.95h-17.5l-.5-.39a28.36 28.36 0 0 1-4.81-4.6 18.11 18.11 0 0 0-3.23-3.16h-3.14c.9 3.82 2.24 7.51 4 11 1.21 2.2 2.46 3.5 3.63 3.67z"/>
        </g>
      </svg>
    </StyledLoader>
  )
}

LoaderBlock.propTypes = {
  loading: PropTypes.bool
}
export default LoaderBlock
